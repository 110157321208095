<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import Avatar from '@/components/Avatar.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useUserStore } from '@/stores/userStore.ts'
import { useWizardStore } from '@/stores/wizardStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);
const error = ref<string|null>(null);

const selectedAvatar = ref<string|null>(null);

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Select a new avatar for your profile'
  } else {
    return 'Select an avatar that suits you.'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const userAvatar = useUserStore().userComputed.avatar
if (userAvatar) {
  selectedAvatar.value = userAvatar
}

const submitForm = async () => {
  if (!selectedAvatar.value) {
    error.value = 'Please select an avatar'
    return;
  }

  error.value = null
  loading.value = true;
  try {
    await useUserStore().updateUser({
      avatar: `${ selectedAvatar.value }`
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

watch(selectedAvatar, (newValue) => {
  if (newValue) {
    error.value = null
  }
})
</script>

<template>
  <StepLayout :headline="headline">

    <form @submit.prevent="submitForm">
      <div class="d-flex gap-3 flex-wrap mx-auto justify-content-center" style="max-width: 840px">
        <Avatar v-for="avatar in 24" :avatarId="`${ avatar }`" class="avatar-selector" @click="selectedAvatar = avatar" 
        :class="{'active':selectedAvatar == avatar, 'inactive':selectedAvatar && selectedAvatar != avatar}"
        />
      </div>
      
      <StepFooter 
        :error="error" 
        :loading="loading"
        :label="buttonLabel" />
    </form>
  </StepLayout>
</template>

<style lang="scss" scoped>
.avatar-selector {
  cursor: pointer;
  opacity:1;
  transition: transform 0.2s, opacity 0.2s;
  &:hover {
    transform: scale(1.2);
  } 
  &.active {
    transform: scale(1.5);
    z-index: 50;
  }
  &.inactive {
    opacity: 0.1;
    // transform: scale(0.6);
    filter: grayscale(100%);
    &:hover {
      filter: grayscale(0);
      opacity: 0.5;
    }
  }
}
</style>
