import axios from 'axios'
import { useUserStore } from '@/stores/userStore.ts'

axios.defaults.baseURL = import.meta.env.VITE_API_URL

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if ( error.response?.status === 401 && error.response?.data?.message !== 'Unauthenticated' ) {
      useUserStore().logOut()
    }

    
    const errorResponse = error.response.data
    let errorMessage = errorResponse.error ?? null
    
    if (errorMessage == null && errorResponse.message) {
      errorMessage = errorResponse.message
    } else if (errorMessage.message) {
      errorMessage = errorMessage.message
    }

    error.formatedMessage = errorMessage ?? "We ran into an error. Please try again."

    return Promise.reject(error);
  }
);