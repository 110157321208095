<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TagBrand from '@/components/wizard/Components/TagBrand.vue'
import TextInput from '@/components/form/TextInput.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])


const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update the key clients you\'ve worked with'
  } else {
    return 'Which key clients, brands or agencies have you worked with?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const userClientExperience = useUserStore().userComputed.experience_details

const loading = ref<boolean>(false);

const experienceClients = ref<Array<object>>([]);
const ClientExperienceError = ref<string|null>(null);

const newClient = ref<string>('');
const newClientWebsite = ref<string>('');

if (userClientExperience) {
  experienceClients.value = userClientExperience
}

const submitForm = async () => {
  if (experienceClients.value.length === 0) {
    ClientExperienceError.value = 'Please add at least one client or brand.'
    return
  }

  ClientExperienceError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      experience_details: JSON.stringify(experienceClients.value)
    })
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    ClientExperienceError.value = e
  }
}

const addClient = () => {
  if (newClient.value && newClientWebsite.value) {
    experienceClients.value.push({ label: newClient.value, website: newClientWebsite.value })
    newClient.value = ''
    newClientWebsite.value = ''
  } else {
    ClientExperienceError.value = 'Please fill out both fields'
  }
}

const removeClient = (client) => {
  experienceClients.value = experienceClients.value.filter((c) => c !== client)
}

const isValidDomain = computed(() => {
  const domain = newClientWebsite.value
  const domainRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+((\/[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})?)?\/?$/
  if (domainRegex.test(domain)) {
    return true
  } else {
    return false
  }
})


watch(experienceClients, (newVal) => {
  if (newVal) {
    ClientExperienceError.value = null
  }
})
</script>

<template>
<StepLayout 
  maxWidth="750px"
  :headline="headline">
  <template v-slot:steptip>
    <p class="text-center small text-muted pb-3">Add up to five key clients.</p>
  </template>

  <form @submit.prevent="submitForm">
    <div class="mx-auto" style="max-width: 1100px;">
      <div class="d-flex flex-wrap gap-3 mb-5 justify-content-center" v-if="experienceClients.length > 0">
        <TagBrand v-for="client in experienceClients" :key="client.value" :data="client" @click="removeClient(client)" />
      </div>
    </div>
    
    <div class="mx-auto clientForm" style="max-width:350px" :class="{'disabled': experienceClients.length >= 5 }">
      <div class="d-flex w-100 flex-column gap-3 gap-md-3">
        <TextInput
          id="newClient"
          label="Name"
          placeholder="Brand or Agency name"
          v-model="newClient"
          @formSubmit="addClient()"
          class="w-100"
        />
        <TextInput
          id="newClientWebsite"
          label="Website"
          placeholder="brand.com"
          v-model="newClientWebsite"
          @formSubmit="addClient()"
          class="w-100"
        />
        <div class="text-start">
          <div class="btn btn-sm btn--outline-dark me-auto no-wrap mt-2 d-block" :class="{'disabled':newClient == '' || !isValidDomain, 'btn-hilight': isValidDomain && newClient }" @click="addClient()">Add</div>
        </div>
      </div>
    </div>
    <StepFooter :error="ClientExperienceError" 
      :loading="loading"
      :label="buttonLabel"
      :disabled="experienceClients.length === 0"
    />
  </form>
</StepLayout>
</template>

<style>
.clientForm.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>