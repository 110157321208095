<script setup lang="ts">
import { onMounted, ref } from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
  },
  label: {
    type: String,
    required: false,
  },
  fieldLayout: {
    type: String,
    default: 'stacked',
  },
  modelValue: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  error: {
    type: String,
    default: '',
  },
})

const initAutocomplete = () => {
    var input = document.getElementById("locationField");
    var autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: {country: 'ZA'} // Restrict to South Africa
    });
    autocomplete.addListener('place_changed', function() {
        var place = autocomplete.getPlace();
        if (!place.geometry) {
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }

        // Get city name
        var city = "";
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] === "locality") {
                city = place.address_components[i].long_name;
                break;
            }
        }

        // Get GPS coordinates
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();

        if (city !== "") {
            // Do something with the city
            props.modelValue.city = city;
            props.modelValue.lat = lat;
            props.modelValue.lng = lng;
        }
    });
}

onMounted(() => {
    initAutocomplete();
})

defineEmits(['update:modelValue', 'formSubmit'])

</script>

<template>
  <div :class="{'layout-inline d-flex flex-column flex-md-row gap-md-3 align-items-md-center justify-content-md-between':fieldLayout == 'inline'}">
    <label :for="id" class="form-label">{{ label }}</label>
    <input 
      type="text"
      class="form-control out-2"
      id="locationField"
      :name="id"
      :placeholder="placeholder"
      :value="modelValue.city"
      autocomplete="off"
      :class="{'is-invalid': error}"
      @keydown.enter.prevent="$emit('formSubmit')"
      @blur="$emit('formSubmit')"
        />

    <p v-if="error" class="error mt-2 text-danger small">{{ error }}</p>
  </div>
</template>

<style lang="scss">
@import "@/sass/_vuestrap.scss";
.pac-container {
  z-index: 100000;
}
.out-2 {
  outline: 1px solid #666;
}
.layout-inline {
  input {
    @include media-breakpoint-up(md) {
      max-width: 55%;
    }
  }
}
</style>
