<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TagSelector from '@/components/wizard/Components/TagSelector.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'
import { useRoleStore } from '@/stores/roleStore'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);


const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update the list of programming languages you know'
  } else {
    return 'Which programming languages do you know?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const selectedProgLang = ref<Array<object>>([]);
const userProgLang = useUserStore().userComputed.programming_details

if (userProgLang) {
  selectedProgLang.value = userProgLang
}

const ProgLangError = ref<string|null>(null);
const ProgLang = ref([
  { value: '', label: '' },
])
const dataLang = useRoleStore().getLanguages

if (dataLang) {
  ProgLang.value = dataLang
}

const mergedProgLang = computed(() => {
  //combine the two arrays and remove duplicates
  let combined = [...ProgLang.value, ...selectedProgLang.value].filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.value === item.value
    ))
  )
  return combined.sort((a, b) => a.label.localeCompare(b.label))
})

const submitForm = async () => {
  if (selectedProgLang.value.length === 0) {
    ProgLangError.value = 'Please select at least one language above'
    return
  }

  ProgLangError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      programming_details: JSON.stringify(selectedProgLang.value)
    })
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    ProgLangError.value = e
  }
}

const toggleClientLanguage = (selectedLanguage: object) => {
  let selected = selectedProgLang.value.findIndex((selectedRoles) => selectedRoles.value == selectedLanguage.value) > -1
  if (selected) {
    removeLanguage(selectedLanguage)
  } else {
    if (selectedProgLang.value.length >= 500) {
      ProgLangError.value = 'You can only select up to five hundred languages.'
      return
    }
    selectedProgLang.value.push(selectedLanguage)
  }
}

const removeLanguage = (language: object) => {
  selectedProgLang.value = selectedProgLang.value.filter((selected) => {
    return selected.value !== language.value
  })
}

watch(selectedProgLang, (newVal) => {
  if (newVal) {
    ProgLangError.value = null
  }
})
</script>

<template>
<StepLayout 
  maxWidth="850px"
  :headline="headline">
  <template v-slot:steptip>
    <p class="text-center small text-muted pb-3">Select from the list below or filter and add your own.</p>
  </template>

  <form @submit.prevent="submitForm">
    <TagSelector
      setMax="20"
      :optionsArray="mergedProgLang"
      :selectedArray="selectedProgLang"
      :error="ProgLangError"
      @update:modelValue="toggleClientLanguage($event)"
    />

    <StepFooter :error="ProgLangError" :loading="loading" :label="buttonLabel" />
  </form>
</StepLayout>
</template>