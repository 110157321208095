<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

defineEmits(['close', 'closeModal'])
defineProps({
  maxWidth: {
    type: String,
    default: '550px',
  },
  showCloser: {
    type: Boolean,
    default: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
  }
})

onMounted (() => {
  document.body.classList.add('modal-open')

  setTimeout(() => {
    const modalOuter = document.querySelector('.modal__outer')

    if (modalOuter !== null) {
      modalOuter.focus()
    }
  }, 200);
})

onUnmounted (() => {
  document.body.classList.remove('modal-open')
})
</script>

<template>
  <Teleport to="#modal-portal">
    <div class="modal__outer fade-in" tabindex="-1" aria-dialog="modal window">
      <div class="modal__shade fade-in" @click="$emit('closeModal')"></div>

      <div class="modal__inner fade-in-after" :style="{ maxWidth: maxWidth }" @click.stop>
        <div class="modal__content shadow" :class="{'pt-4': showCloser}" >
          <div class="close-x" v-if="showCloser">
            <a href="#" 
              @click.prevent="$emit('closeModal')" 
              class="fs-3 lh-1 p-2 d-inline-block mb-2"><i class="icon-x"></i></a>
          </div>
          
          <div class="modal__content--inner pt-2 mt-2 mb-2" :class="{'px-5 pb-5': !noPadding, 'pt-5': !showCloser}">
            <div>
              <slot></slot>
            </div>

            <div v-if="$slots.footer">
              <slot name="footer"></slot>
            </div>
          </div>

        </div>
      </div>
      
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@import "@/sass/vuestrap";
.close-x {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.modal {
  &__outer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__shade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);

    @include media-breakpoint-up(md) {
      background-color: rgba(0,0,0,0.4);
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    
    @include media-breakpoint-up(md) {
      width: 95%;
      margin: 50px auto;
      min-height: calc(100% - 100px);
    }
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;

    @include media-breakpoint-up(md) {
      border-radius: 15px;
    }
  }
}

.hideCloser {
  opacity: 0;
  pointer-events: none;
}

.fade-in {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-after {
  animation: fadeInAfter 0.2s ease-in-out;
}

@keyframes fadeInAfter {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


</style>

<style>
body.modal-open {
  overflow: hidden;
}
</style>
