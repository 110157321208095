<script setup lang="ts">
import { ref } from 'vue'

import { useUserStore } from '@/stores/userStore.ts'

import ModalComponent from '@/components/ModalComponent.vue'

import Button from '@/components/form/Button.vue'
import SelectInput from '@/components/form/SelectInput.vue'
import TextInput from '@/components/form/TextInput.vue'
import SelectButtons from '@/components/form/SelectButtons.vue'

const props = defineProps({
  modalData: {
    type: String,
    required: true,
  },
  newRole: {
    type: Boolean,
    default: false,
  },
  roleLabel: {
    type: String,
    default: '',
  },
});

// page variables
const emit = defineEmits(['closeModal'])

const userPrimaryDetails = useUserStore().userComputed.primary_details
const userSecondaryDetails = useUserStore().userComputed.secondary_details

const error = ref<string|null>(null);

const startYear = ref<string>('')
const primaryOptions = () => {
  const options = []
  const currentYear = new Date().getFullYear()
  for (let years = currentYear; years > currentYear - 17; years--) {
    let label = (currentYear - years).toString();
    if (years === currentYear - 16) {
      label = 'More than 15 years'
    } else if (years === currentYear - 0) {
      label = 'Less than a year'
    } else if (years === currentYear - 1) {
      label = '1 year'
    } else {
      label = label + ' years'
    }
    options.push({ value: years.toString(), label: label })
  }
  return options
}

const roleName = ref<string>('')
roleName.value = props.roleLabel

const currentLevel = ref<string|null>(null)
const currentLevelOptions = ['Junior', 'Mid', 'Senior', 'Lead', 'Director']

if (props.modalData == 'primary') {
  if (userPrimaryDetails) {
    const primaryDetails = userPrimaryDetails
    startYear.value = primaryDetails.year
    currentLevel.value = primaryDetails.level
  }
} else if (props.modalData == 'secondary') {
  if (userSecondaryDetails) {
    const secondaryDetails = userSecondaryDetails
    startYear.value = secondaryDetails.year
    currentLevel.value = secondaryDetails.level
  }
}
  
const closeModal = (saveDetails: boolean = false) => {
  if (saveDetails || userPrimaryDetails && userSecondaryDetails) {

    if (!roleName.value && props.newRole) {
      error.value = 'Role name is required.'
      return;
    }

    if (!currentLevel.value) {
      error.value = 'Current level is required.'
      return;
    }

    if (!startYear.value) {
      error.value = 'Start year is required.'
      return;
    }

    emit('closeModal', {
      startYear: startYear.value,
      currentLevel: currentLevel.value,
      roleName: roleName.value
    })
  } else {
    emit('closeModal')
  }
}
</script>

<template>
  <ModalComponent 
    showCloser 
    maxWidth="730px"
    @close="closeModal()">
    <div class="mb-4">
      <span v-if="newRole">
        <h2 class="mb-2">Add your role</h2>
        <p class="m-0">Add your role and level to help us match you with the most suitable jobs that align with your expertise.</p>
      </span>
      <span v-else>
        <h3 class="mb-4">Rate yourself</h3>
        <p class="m-0">Select your proficiency level to assist in matching you with the most suitable jobs that align with your expertise.</p>
      </span>
    </div>

    <form @submit.prevent="closeModal(true)">
      <div class="inputGroup" v-if="newRole">
        <TextInput
          id="role"
          label="<b class='mb-2'>Role Name</b>"
          v-model="roleName"
          placeholder="Enter your role"
          class="text-start"
          />
      </div>

      <div class="inputGroup">
        <p class="fw-bold mb-3">Select your level:</p>
        <div class="mx-auto" style="max-width: 550px">
          <SelectButtons 
            id="currentLevel"
            :optionsArray="currentLevelOptions"
            v-model="currentLevel" />
        </div>
      </div>

      <div class="inputGroup">
        <p class="fw-bold mb-3">How long have you been in this role?</p>
        <SelectInput
          id="startYear"
          placeholder="Select a Year"
          v-model="startYear"
          class="text-start"
          :optionsArray="primaryOptions()" />
      </div>

      <div class="text-center mt-4 mb-n4">
        <p class="text-danger" v-if="error">{{ error }}</p>
        <Button type="submit" class="btn-dark px-6">Continue</Button>
      </div>      
    </form>

  </ModalComponent>
</template>
