<script setup lang="ts">
  import { ref } from 'vue'
  const currentYear = ref(new Date().getFullYear())
</script>

<template>
  <div class="container-fluid mt-6 pb-4">
    <div class="row footerRow">
      <div class="col-md-6 order-md-last text-center text-md-end py-2">
        <div class="d-flex flex-column flex-md-row gap-2 my-4 my-md-0 justify-content-center justify-content-md-end">
          <a href="https://secretsource.co.za/terms-of-use" target="_blank" class="me-3 highlight-back text-muted"><span>Terms of Use</span></a>
          <a href="https://secretsource.co.za/privacy-policy" target="_blank" class="me-3 highlight-back text-muted"><span>Privacy Policy</span></a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-center  justify-content-md-start text-lg-start align-items-center flex-wrap">
          <img src="../assets/images/logo-s.svg" class="logo me-4" alt="secretsource">
          <p class="mb-0 small text-muted">Copyright © <span class="d-none">2018-</span>{{ currentYear }} Secretsource. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  width: 30px;
  opacity: 0.8;
}
.footerRow {
  border-top: 1px solid #CCC;
  padding-top:20px;

  a {
    font-size: 0.9em;
  }
}
.desaturate {
  filter: grayscale(100%) brightness(1.05);
  pointer-events: none;
}
</style>