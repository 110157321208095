<script setup lang="ts">
import ToggleButton from '@/components/form/ToggleButton.vue'

defineEmits(['btnClicked']);
defineProps({
  image: {
    type: String,
    required: false,
  },
  materialIcon: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  isChecked: {
    type: Boolean,
    required: true,
  },
  shape: {
    type: String,
    default: 'radio',
  },
  showDots: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
});

const getImage = (imageString: string) => {
  return new URL(`/src/assets/images/${imageString}`, import.meta.url)
}
</script>

<template>
  <div 
    class="blockBtn d-flex justify-content-between align-items-center gap-3 py-4 pe-4 pointer-curser" 
    :class="{ 'isInvalid': error, 'checked': isChecked}"
    @click.prevent="$emit('btnClicked')">

    <div class="flex-grow-0 flex-shrink-0" v-if="image">
      <img :src="getImage(image)" alt="icon" class="button-img" />
    </div>
      <div class="flex-grow-0 flex-shrink-0 material-box" v-if="materialIcon">
        <span class="material-symbols-outlined">
          {{ materialIcon }}
        </span>
      </div>

    <div class="flex-grow-1 blockBtn__label ps-2">
      <h4 v-html="label" class="m-0 font-weight-700"></h4>
    </div>

    <div class="flex-grow-0 flex-shrink-0" :class="{'checkbox': shape == 'checkbox'}">
      <ToggleButton 
        @btnClicked="$emit('btnClicked')"
        :isChecked="isChecked"
        :showDots="showDots" />
    </div>

  </div>
</template>

<style lang="scss">
@import "@/sass/vuestrap";

.blockBtn {
  width: 340px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: rgba(#000, 0.15) 1px 3px 8px 0;
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  // border: 2px solid transparent;
  &.checked {
    // background-color: #f5f5dd;
  }
  &.isInvalid {
    border: 2px solid $danger;
  }
  &:hover {
    .toggleButton {
      border-color: $primary;
      background-color: $secondary;
    }
    .toggleButton__circle {
      background-color: $secondary;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 80px;
    height:75px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -25px;
    border-radius: 0 15px 15px 0;
    background-color:  $primary;
    opacity:0.05;
  }
  .blockBtn__label h4 {
    font-size: 24px;
    line-height: 28px;
  }
}
.material-box {
  width:70px;
  text-align: right;
  opacity: 0.85;
}
.material-symbols-outlined {
  font-size: 55px;
  font-weight: 100;
}
.button-img {
  max-width: 50px;
  max-height: 62px;
  object-fit: contain;
}
</style>