<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import SelectInput from '@/components/form/SelectInput.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useUserStore } from '@/stores/userStore.ts'
import { useWizardStore } from '@/stores/wizardStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);
const error = ref<string|null>(null);


const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const userFieldExperience = useUserStore().userComputed.field_details
const inField = useUserStore().getField()
const headlineText = 'How long have you been in the ' + inField.name + ' field?'

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const selectedExperience = ref<string>('');

if (userFieldExperience) {
  selectedExperience.value = userFieldExperience
}

const options = []

const submitForm = async () => {
  if (!selectedExperience.value) {
    error.value = 'Please select an option'
    return
  }

  error.value = null
  loading.value = true;
  try {
    await useUserStore().updateUser({
      field_details: `${ selectedExperience.value }`
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

onMounted(() => {
  const currentYear = new Date().getFullYear()
  for (let years = currentYear; years > currentYear - 17; years--) {
    let label = (currentYear - years).toString();
    if (years === currentYear - 16) {
      label = 'More than 15 years'
    } else if (years === currentYear - 0) {
      label = 'Less than a year'
    } else if (years === currentYear - 1) {
      label = '1 year'
    } else {
      label = label + ' years'
    }
    options.push({ value: years.toString(), label: label })
  }
})

watch(selectedExperience, (newVal) => {
  if (newVal) {
    error.value = null
  }
})
</script>

<template>
  <StepLayout 
    maxWidth="580px"
    :headline="headlineText">
    <form @submit.prevent="submitForm">
      <SelectInput
        id="fieldExperience"
        class="w-100"
        label="Select the number of years in the field:"
        inlineLabel
        v-model="selectedExperience"
        :optionsArray="options"
        :error="error" />
      <StepFooter :loading="loading" :label="buttonLabel" />
    </form>
  </StepLayout>
</template>