import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import axios from 'axios'

export const useMessagesStore = defineStore('messages', () => {

  const messageSent = ref<boolean>(false);
  const messages = ref([])

  const messageHistory = computed(() => messages.value)
  const messageSentComputed = computed(() => messageSent.value)

  const fetchMessageHistory = () => {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: `/v0/messages`
      }).then(function (response) {
        messages.value = response.data.history
        resolve(true)
      }).catch(function (error) {
        reject(error.formatedMessage)

      });
    })
  }

  const messageUser = (messageDetails) => {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: `/v0/message`,
        data: messageDetails
      }).then(function (response) {
        messageSent.value = true
        
        if (response.data.new_message) {
          messages.value.unshift(response.data.new_message)
        }

        resolve(true)
      }).catch(function (error) {
        reject(error.formatedMessage)

      });
    })
  }

  const resetMessageSent = () => {
    messageSent.value = false
  }

  return { 
    messageHistory,
    messageSentComputed,

    fetchMessageHistory,
    messageUser,
    resetMessageSent
  }
})
