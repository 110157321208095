<script setup lang="ts">
</script>

<template>
 <div class="dropdown__container bg-white rounded shadow py-2">
    <slot></slot>
 </div>
</template>

<style lang="scss">
@import "@/sass/_vuestrap.scss";
.dropdown {
   &__container {
      position: absolute;
      right:0;
      top: calc(100% + 5px);
      width: 200px;
      max-width: 85vw; 
   }
}
.drop-link {
   display: flex;
   align-items: center;
   padding: 0.5rem 1rem;
   color: $dark;
   text-decoration: none;
   transition: background-color 0.2s;
   span.icon {
      margin-right: 0.5rem;
      font-size: 22px;
   }
   &:hover {
      background-color: $light;
   }
}
</style>