var domain = import.meta.env.VITE_COOKIE_DOMAIN ?? null

var getCookie = function (name) {
  var value = null

  document.cookie.split('; ').forEach(row => {
    let rowIndex = row.split('=')

    if (rowIndex.length > 1 && rowIndex[0] == name) {
      value = rowIndex[1]

      return false
    }

    return false
  })

  return value
}

var setCookie = function (name, value, days) {
  var cookieString = `${name}=${value}; path=/;`
  if (domain) {
    cookieString += `domain=${ domain };`
  }

  if (days) {
    var d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
    
    cookieString += ` expires=${ d.toGMTString() }`
  }

  document.cookie = cookieString
}

var deleteCookie = function (name) {
  this.setCookie(name, '')
}

var clearCookies = function () {
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=; domain=' + domain + '; expires=' + new Date().toUTCString() + ';path=/')
  })
}

export default {
  getCookie,
  setCookie,
  deleteCookie,
  clearCookies
}
