<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import SelectInput from '@/components/form/SelectInput.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'
import ModalOver from '@/components/wizard/Modals/AvailabilityDatepicker.vue'
import SubTab from '@/components/wizard/Components/SubTab.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'


const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const modalToShow = ref<string|null>(null);

const contractAvailability = ref<string>('');
const contractError = ref<string|null>(null);
const contractOptions: { value: string, label: string }[] = [
  { value: 'immediate', label: 'Immediately' },
  { value: '1w', label: 'With 1 week notice'},
  { value: '1m', label: 'With 1 month notice'},
  { value: '2m', label: 'With 2 months notice'},
  { value: '3m', label: 'With 3 months notice'},
  { value: 'date', label: 'On this date ...'}
];
const dateValue = ref<string>('');
const freelanceCapacity = ref<string>('');
const freelanceError = ref<string|null>(null);

const workDetails = useUserStore().userComputed.work_details
const headline = ref('What is your availability and capacity?')
if (workDetails && !editMode.value) {
  if (workDetails.fixed && !workDetails.freelance) {
    headline.value = 'What is your availability?'
  }
  if (!workDetails.fixed && workDetails.freelance) {
    headline.value = 'What is your capacity?'
  }
}
if (editMode.value) {
  headline.value = 'Update your availability and capacity.'
  if (workDetails.fixed && !workDetails.freelance && !workDetails.overflow) {
    headline.value = 'Update your availability'
  }
  if (!workDetails.fixed && workDetails.freelance) {
    headline.value = 'Update your capacity'
  }
}

const userAvailability = useUserStore().userComputed.availability
if (userAvailability) {
  contractAvailability.value = userAvailability.contract
  dateValue.value = userAvailability.date
  freelanceCapacity.value = userAvailability.freelance
}

const freelanceOptions = [
  { value: '0.5', label: '0.5 Day' },
  { value: '1', label: '1 Day' },
  { value: '1.5', label: '1.5 Days' },
  { value: '2', label: '2 Days' },
  { value: '2.5', label: '2.5 Days' },
  { value: '3', label: '3 Days' },
  { value: '3.5', label: '3.5 Days' },
  { value: '4', label: '4 Days' },
  { value: '4.5', label: '4.5 Days' },
  { value: '5', label: '5 Days' },
]

const prettyDate = computed(() => {
  if (dateValue.value) {
    const date = new Date(dateValue.value)
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })
  }
  return ''
})

const clearData = (type: string) => {
  if (type == 'dateData') {
    dateValue.value = ''
    contractAvailability.value = ''
  }
}

const submitForm = async () => {
  if (!contractAvailability.value && !freelanceCapacity.value) {
    contractError.value = 'Please select an option'
    freelanceError.value = 'Please select an option'
  }

  if(contractError.value || freelanceError.value) {
    return;
  }

  loading.value = true;
  
  try {
    await useUserStore().updateUser({
      availability: JSON.stringify({
        contract: contractAvailability.value,
        date: dateValue.value,
        freelance: freelanceCapacity.value
      })
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

const showModal = (modal: string) => {
  modalToShow.value = modal
}

const modalClose = (submitData: any) => {
  if (submitData.dateValue) {
    contractAvailability.value = 'date'
    dateValue.value = submitData.dateValue
  } else {
    contractAvailability.value = ''
  }
  modalToShow.value = null
}

watch(contractAvailability, (newVal) => {
  if (newVal) {
    contractError.value = null
  }
  if (newVal == 'date') {
    showModal('datepicker')
  } else {
    dateValue.value = ''
  }
})

watch(freelanceCapacity, (newVal) => {
  if (newVal) {
    freelanceError.value = null
  }
})

</script>

<template>
<ModalOver 
  :modalData="dateValue"
  v-if="modalToShow == 'datepicker'" 
  @closeModal="modalClose"
/>
<StepLayout 
  maxWidth="600px"
  :headline="headline">
  <form @submit.prevent="submitForm">
    <div class="mx-auto d-flex flex-column align-items-start flex-md-row gap-3 gap-md-4" style="max-width: 900px;" v-if="workDetails.fixed">
      <span>
        <p class="mb-2"><b>Fixed-term contract</b></p>
        <p class="mb-2">When are you next available to start a <span class="no-wrap">fixed-term</span>&nbsp;contract?</p>
      </span>
      <div>
        <SelectInput
          id="contractAvailability"
          v-model="contractAvailability"
          :optionsArray="contractOptions"
          :error="contractError" />

          <SubTab v-if="dateValue" @clear="clearData('dateData')" class="justify-content-start justify-content-sm-end">
          <span class="sub-tab" @click="showModal('datepicker')">{{ prettyDate }}</span>
          </SubTab>
      </div>
    </div>

    <hr class="my-4" v-if="workDetails.freelance && workDetails.fixed">

    <div v-if="workDetails.freelance || workDetails.overflow">
      
      <div class="mx-auto d-flex flex-column align-items-start flex-md-row gap-3 gap-md-4" style="max-width: 900px;">
        <span>
          <p class="mb-2"><b>Capacity</b></p>
          <p class="mb-2">How much available capacity do you currently have <b>per&nbsp;week?</b></p>
        </span>
        <SelectInput
          id="freelanceCapacity"
          v-model="freelanceCapacity"
          :optionsArray="freelanceOptions"
          :error="freelanceError" />
      </div>
    </div>
  
    <StepFooter :loading="loading" :label="buttonLabel" />
  </form>
</StepLayout>
</template>