<script setup lang="ts">
import { ref, computed } from 'vue'

import ModalComponent from '@/components/ModalComponent.vue'
import Button from '@/components/form/Button.vue'

const props = defineProps({
  modalData: {
    type: String as () => string|null,
    required: true,
  }
});

// page variables
const emit = defineEmits(['closeModal'])
const error = ref<string|null>(null)
const dateValue = ref<string>('')

if (props.modalData) {
  dateValue.value = props.modalData
}

const closeModal = (submit: boolean = false) => {
  let submitData = {
    dateValue: dateValue.value,
  }
  error.value = null

  if (submit) {
    if (!dateValue.value) {
      error.value = 'Please select a date'
    }
    if (error.value ) return;
  }

  emit('closeModal', submitData)
}
</script>

<template>
  <ModalComponent 
    showCloser 
    maxWidth="730px"
    @close="closeModal()">
  
    <div class="mb-4">
      <h3 class="mb-4">Select a date</h3>
      <p class="m-0">Tell us when you'll next be available for a fixed-term contract.</p>
    </div>
  
    <form @submit.prevent="closeModal(true)">
  
      <div class="inputGroup p-4">
        <div class="d-flex flex-column flex-md-row gap-2 align-items-start align-items-md-center justify-content-between">
          <label for="dateValue">Next available date:</label>
          <input 
            type="date" 
            id="dateValue" 
            v-model="dateValue" 
            class="form-control datepicker max-50 mb-0"
            :class="{ 'is-error': error, 'is-filled': dateValue}"
             />
        </div>
      </div>
      
      <div class="text-center mt-4 mb-n4">
        <p class="text-danger" v-if="error">{{ error }}</p>
        <Button type="submit" class="btn-dark px-6">Continue</Button>
      </div>      
    </form>
  
  </ModalComponent>
</template>

<style scoped>
.max-50 {
  max-width: 210px;
}
.datepicker {
  padding: 9px 15px; 
  position: relative;
  border: 2px solid #666;
  &:hover {
    border-color: #999;
  }
  &.is-filled {
    &:after {
      content: '';
      position: absolute;
      right: 50px;
      left: 10px;
      bottom: 9px;
      height: 9px;
      background-color: #FFFF00;
      mix-blend-mode: multiply;
    }
    &:hover {
      &:after {
        content: none;
      }
    }
  }
}
</style>