<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import { createHead } from 'unhead'
import { RouterView } from 'vue-router'
import { useRouter } from 'vue-router'

import AppNav from '@/components/AppNav.vue'
import Footer from '@/components/Footer.vue'
import Wizard from '@/components/wizard/Wizard.vue'
import AuthModals from '@/components/Auth/AuthModals.vue'

const router = useRouter()

const showNav = computed(() => {
  return !router.currentRoute.value.meta.hideNav
})

createHead()

import { useUserStore } from '@/stores/userStore.ts'

const loaded = ref(false)
const userIsLoggedIn = computed(() => useUserStore().authSet)
const userInfo = computed(() => useUserStore().userComputed)
// const currentRoute = computed(() => router.currentRoute.value.name)


const loadPage = async () => {
  await router.isReady()

  setTimeout(() => {
    loaded.value = true
  }, 1000)
}


onMounted(() => {
  loadPage()
})
</script>

<template>
  <AppNav v-show="showNav" />
  
  <Wizard />
  
  <main>
    <RouterView v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </RouterView>
  </main>
  
  <Footer v-show="showNav" />
  
  <AuthModals />
  
  <div 
    class="welcomeLoading" 
    :class="{'done': loaded && ((userIsLoggedIn && userInfo.email_verified) || $route.meta?.hideBackDrop)}">
  </div>

</template>

<style lang="scss">
main {
  min-height: calc(100vh - 250px);
}
.welcomeLoading {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: #fff;
  background-image: url('@/assets/images/tileme.png');background-repeat: repeat;
  background-size: 400px;
  z-index: 99999;
  opacity:1;
  transition: opacity 0.5s;
  &.done {
    pointer-events: none;
    opacity:0;
  }
}

</style>
