<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import BlockBtn from '@/components/wizard/Components/BlockBtn.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'
import ModalOver from '@/components/wizard/Modals/LocationPicker.vue'
import SubTab from '@/components/wizard/Components/SubTab.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);


const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update your preferred work arrangements'
  } else {
    return 'Which work arrangements suit you best?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})


const error = ref<string|null>(null);

const modalToShow = ref<string|null>(null);

const workRemote = ref<boolean>(false);
const workOnSite = ref<boolean>(false);
const workHybrid = ref<boolean>(false);
const workLocation = ref<object|null>(null);

const userWorkArrangement = useUserStore().userComputed.work_arrangement
const userLocationDetails = useUserStore().userComputed.location_details

if (userWorkArrangement) {
  workRemote.value = userWorkArrangement.remote
  workOnSite.value = userWorkArrangement.on_site
  workHybrid.value = userWorkArrangement.hybrid
}
if(userLocationDetails) {
  workLocation.value = userLocationDetails
}

const submitForm = async () => {
  if (!workRemote.value && !workOnSite.value && !workHybrid.value) {
    error.value = 'Please select an option above'
    return;
  }

  error.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      work_arrangement: JSON.stringify({
        remote: workRemote.value,
        on_site: workOnSite.value,
        hybrid: workHybrid.value
      }),
      location_details: JSON.stringify(workLocation.value)
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

const showModal = (type: string) => {
  modalToShow.value = type
}

const checkModal = (type: string) => {
    if (!workLocation.value) {
      if (type == 'workOnSite') {
        if(workLocation.value) {}
        modalToShow.value = 'workOnSite'
      } else if (type == 'workHybrid') {
        modalToShow.value = 'workHybrid'
      }
    } else {
      if (type == 'workOnSite') {
        workOnSite.value = !workOnSite.value
      } else if (type == 'workHybrid') {
        workHybrid.value = !workHybrid.value
      }
    }
}

const modalClose = (submitData: any) => {
  if (submitData) {
    workLocation.value = submitData.locationValue
    if(modalToShow.value == 'workOnSite' && submitData.locationValue) {
      workOnSite.value = true
    } else if(modalToShow.value == 'workHybrid' && submitData.locationValue) {
      workHybrid.value = true
    }
  } 
  modalToShow.value = null
}

const clearData = (type: string) => {
  if (type == 'workOnSite') {
    workOnSite.value = false
  } else if (type == 'workHybrid') {
    workHybrid.value = false
  }
}

watch(workRemote, (newVal) => {
  if (newVal) {
    error.value = null;
  }
})
watch(workOnSite, (newVal) => {
  if (newVal) {
    error.value = null;
  }
})
watch(workHybrid, (newVal) => {
  if (newVal) {
    error.value = null;
  }
})

</script>

<template>
  <ModalOver 
    :modalData="workLocation"
    v-if="modalToShow == 'workOnSite' || modalToShow == 'workHybrid'"
    @closeModal="modalClose"
  />
  
  <StepLayout 
    maxWidth="1800px"
    :headline="headline">
    <template v-slot:steptip>
      <p class="text-center small text-muted pb-3">Select as many as you want 🌟</p>
    </template>
    <form @submit.prevent="submitForm">
      
      <div class="d-flex flex-wrap align-items-start gap-4 justify-content-center">
          <BlockBtn 
            :isChecked="workRemote"
            @btnClicked="workRemote = !workRemote"
            materialIcon="cottage"
            shape="checkbox"
            label="Remote" 
            :error="!!error" />

            <div>
              <BlockBtn 
              :isChecked="workOnSite"
              @btnClicked="checkModal('workOnSite')"
              materialIcon="apartment"
              label="On-site"
              shape="checkbox"
              :showDots="!workLocation"
              :error="!!error"/>
              
              <SubTab v-if="workOnSite && workLocation" @clear="clearData('workOnSite')" class="justify-content-end">
                <span class="sub-tab" @click="showModal('workOnSite')">{{ workLocation.city }}</span>
              </SubTab>
            </div>

            <div>
              <BlockBtn 
                :isChecked="workHybrid"
                @btnClicked="checkModal('workHybrid')"
                materialIcon="home_work"
                label="Hybrid"
                shape="checkbox"
                :showDots="!workLocation"
                :error="!!error"/>
                  
              <SubTab v-if="workHybrid && workLocation" @clear="clearData('workHybrid')" class="justify-content-end">
                <span class="sub-tab" @click="showModal('workHybrid')">{{ workLocation.city }}</span>
              </SubTab>
            </div>
      </div>
    
      <StepFooter :error="error" :loading="loading" :label="buttonLabel" />
    </form>
  </StepLayout>
</template>
