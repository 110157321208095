<script setup lang="ts">
import { ref } from 'vue'

defineProps({
  id: {
    type: String,
    default: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
  },
  label: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  error: {
    type: String,
    default: '',
  },
  isDisabled: {
    type: Boolean,
    required: false
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  canShow: {
    type: Boolean,
    default: false,
  },
})
defineEmits(['update:modelValue'])
const showPassword = ref(false)

</script>

<template>
  <div>
    <label :for="id" class="form-label">{{ label || placeholder }}</label>
    <div class="passwordInput">
      <input
        :type="showPassword && canShow ? 'text' : 'password'"
        class="form-control" 
        :class="{'is-invalid': isInvalid}"
        :id="id"
        :name="id"
        :placeholder="placeholder"
        :disabled="isDisabled" 
        autocomplete="off"
        :value="modelValue"
        @change="$emit('update:modelValue', (<HTMLTextAreaElement>$event.target).value ?? '')" />
      
      <a href="#" @click.prevent="showPassword = !showPassword" class="passwordInput__toggle" :class="{'text-dark': !showPassword}" v-if="canShow">
        <i :class="[showPassword ? 'icon-eye' : 'icon-eye-off']"></i>
      </a>
    </div>

    <p v-if="error" class="error mt-2 text-danger small">{{ error }}</p>
  </div>
</template>

<style lang="scss">
.passwordInput {
  position: relative;
  &__toggle {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    line-height: 0;
    font-size: 20px;
  }
}
</style>
