<script setup lang="ts">
import { ref } from 'vue'

import TextInput from '@/components/form/TextInput.vue';
import PasswordInput from '@/components/form/PasswordInput.vue'
import Button from '@/components/form/Button.vue';

import { useVuelidate } from '@vuelidate/core'
import { minLength, maxLength, email, required, helpers } from '@vuelidate/validators'

import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['closeModal'])

const loading = ref<boolean>(false)
const error = ref<string|null>(null)

const inviteCode = ref<string>('');
const emailAddress = ref<string>('');
const password = ref<string>('');

const v$ = useVuelidate({
  inviteCode: { 
    required: helpers.withMessage('Invite code is required.', required),
    minLength: helpers.withMessage('Invite code should be atleast 6 characters.', minLength(6)),
    maxLength: helpers.withMessage('Invite code should only be 6 characters.', maxLength(6)),
  },
  emailAddress: {
    required: helpers.withMessage('Email is required', required),
    email: helpers.withMessage('Valid email address required.', email)
  },
  password: {
    required: helpers.withMessage('Password is required.', required),
    minLength: helpers.withMessage('Password needs to be atleast 6 characters.', minLength(8)),

    containsUpper: helpers.withMessage('Password requires 1 uppercase letter.', function(value: string) {
      return /[A-Z]/.test(value)
    }),
    containsLower: helpers.withMessage('Password requires 1 lowercase letter.', function(value: string) {
      return /[a-z]/.test(value)
    }),

    containsNumber: helpers.withMessage('Password requires 1 number.', function(value: string) {
      return /[0-9]/.test(value)
    }),
    containsSpecial: helpers.withMessage('Password needs 1 special character.', function(value: string) {
      return /[#?!@$%^&*-]/.test(value)
    }),
  },
}, { inviteCode, emailAddress, password }, { $lazy: true });

const login = async () => {
  v$.value.$touch();
  if (v$.value.$invalid) return;

  // loading.value = true;
  // try {
  //   await useUserStore().logIn({
  //       email: emailAddress.value,
  //       password: password.value,
  //       remember: true
  //     })

  //   loading.value = false;
  //   emit('closeModal')

  // } catch (requestError) {
  //   loading.value = false;
  //   error.value = requestError

  // }
}

</script>

<template>
  <div>
    <div>
      <h4 class="h3">Get started</h4>
      <p>Use your signup code to unlock a vibrant community that celebrates innovation and collaboration. Apply now to shape the future of creativity with us!</p>
    </div>

    <form @submit.prevent="login" class="d-flex flex-column gap-3">
      <TextInput
        id="inviteCode"
        label="Signup code"
        placeholder="abc123"
        v-model="v$.inviteCode.$model"
        :error="(v$.inviteCode.$dirty && v$.inviteCode.$invalid) ? v$.inviteCode.$errors[0].$message : ''" />
        
      <TextInput
        id="email"
        label="Email Address"
        placeholder="you@cooldomain.com"
        v-model="v$.emailAddress.$model"
        :error="(v$.emailAddress.$dirty && v$.emailAddress.$invalid) ? v$.emailAddress.$errors[0].$message : ''" />


      <PasswordInput
        id="password"
        label="Choose a password"
        placeholder="*******"
        v-model="v$.password.$model"
        :isInvalid="(v$.password.$dirty && v$.password.$invalid)"
        :error="(v$.password.$dirty && v$.password.$invalid) ? v$.password.$errors[0].$message : ''" />
      
      <div class="text-end">
        <Button 
          class="btn-dark" 
          :disabled="loading" 
          :loading="loading">Login</Button>
      </div>
    </form>

  </div>

</template>
