<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import BlockBtn from '@/components/wizard/Components/BlockBtn.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'
import ModalFixed from '@/components/wizard/Modals/WorkType_fixed.vue'
import ModalOver from '@/components/wizard/Modals/WorkType_overflow.vue'
import SubTab from '@/components/wizard/Components/SubTab.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const workDetails = useUserStore().userComputed.work_details
const roleDetails = useUserStore().userComputed.primary_details

const loading = ref<boolean>(false);



const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update the type of work you want'
  } else {
    return 'What type of work are you looking for?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const error = ref<string|null>(null);

const typeWork = ref<object>({
  freelance: null,
  fixed: null,
  overflow: null
});

if (workDetails) {
  typeWork.value.freelance = workDetails.freelance
  typeWork.value.fixed = workDetails.fixed
  typeWork.value.overflow = workDetails.overflow
}

const modalToShow = ref<string|null>(null);

const submitForm = async () => {
  if (!typeWork.value.freelance && !typeWork.value.fixed && !typeWork.value.overflow) {
    error.value = 'Please select one or more options above'
    return;
  }

  error.value = null
  loading.value = true;
  try {
    await useUserStore().updateUser({
      work_details: JSON.stringify(typeWork.value)
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

const showModal = (modal: string) => {
  if(modal == 'freelance') {
    modalToShow.value = modal;
  } else if(modal == 'fixed') {
    modalToShow.value = modal;
  }
}

const clearData = (type: string) => {
  if(type == 'freelance') {
    typeWork.value.freelance = null
  } else if(type == 'fixed') {
    typeWork.value.fixed = null
  }
}

const modalClose = (submitData: any) => {
  error.value = null;
  if (submitData) {
    if(modalToShow.value == 'freelance') {
      typeWork.value.freelance = submitData
    } else if(modalToShow.value == 'fixed') {
      typeWork.value.fixed = submitData
    }
  }

  modalToShow.value = null;
}

watch(typeWork, (newVal) => {
  if (newVal) {
    error.value = null;
  }
})
</script>

<template>
  <StepLayout 
    maxWidth="1200px"
    :headline="headline">
    <template v-slot:steptip>
      <p class="text-center small text-muted pb-3">Select as many as you like&nbsp; 🙌</p>
    </template>


    <ModalFixed 
      :modalData="typeWork.fixed"
      v-if="modalToShow == 'fixed'" 
      @closeModal="modalClose"/>
    <ModalOver 
      :modalData="typeWork.freelance"
      v-if="modalToShow == 'freelance'" 
      @closeModal="modalClose"/>

    <form @submit.prevent="submitForm">
      
      <div class="d-flex flex-wrap gap-3 justify-content-center">

        <div class="text-start h-100">
          <BlockBtn 
            :isChecked="typeWork.fixed !== null"
            @btnClicked="showModal('fixed')"
            materialIcon="contract_edit"
            shape="checkbox"
            label="Fixed-term" 
            :error="!!error"
            showDots />


          <SubTab v-if="typeWork.fixed" @clear="clearData('fixed')" class="justify-content-start justify-content-sm-end">
            <div v-if="typeWork.fixed" class="sub-tab" @click="showModal('fixed')">
              <span v-if="typeWork.fixed.minLength == '-1' && typeWork.fixed.maxLength == '-1'">Any length</span>
              <span v-else>
                <span v-if="typeWork.fixed.minLength != '-1'">From {{ typeWork.fixed.minLength }} <span v-if="typeWork.fixed.maxLength != '-1' && typeWork.fixed.maxLength !== typeWork.fixed.minLength">to </span> </span>
                <span v-else>Up to </span>
                <span v-if="typeWork.fixed.maxLength !== typeWork.fixed.minLength && typeWork.fixed.maxLength != '-1'"> {{ typeWork.fixed.maxLength }} </span> months
              </span>
            </div>
          </SubTab>

        </div>
          

        <div class="text-start h-100">
          <BlockBtn 
            :isChecked="typeWork.freelance"
            @btnClicked="typeWork.freelance = !typeWork.freelance"
            materialIcon="accessibility_new"
            label="Freelance" 
            shape="checkbox"
            :error="!!error" />
        </div>


<!-- Otehr icons are domino_mask, sprint, supervisor_account, hail, hub -->

<div class="text-start h-100">
  <BlockBtn 
    :isChecked="typeWork.overflow"
    @btnClicked="typeWork.overflow = !typeWork.overflow"
    materialIcon="support"
    label="Overflow" 
    shape="checkbox"
    :error="!!error" />
</div>

        <div class="w-100">
          <p class="text-muted px-4 mb-0 mt-4 text-center mx-auto" style="max-width: 500px;">Grow your network and income by connecting with other <span class="text-lowercase">{{ roleDetails.roleName }}</span>s for <b>overflow work</b>.</p>
          <p class="text-muted px-4 mb-0 mt-4 text-center mx-auto d-none" style="max-width: 500px;">You can select multiple options  🙌</p>
        </div>
      </div>


      <StepFooter
        :error="error" 
        :loading="loading"
        :label="buttonLabel"
        />
    </form>
  </StepLayout>
</template>
