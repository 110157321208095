<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TagQualification from '@/components/wizard/Components/TagQualification.vue'
import TextInput from '@/components/form/TextInput.vue'
import SelectInput from '@/components/form/SelectInput.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const userQualifications = useUserStore().userComputed.qualifications_details

const loading = ref<boolean>(false);



const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update your qualifications'
  } else {
    return 'What qualifications do you have?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const continueLabel = ref<string>('Continue')

const qualificationsList = ref<Array<object>>([]);
if (userQualifications) {
  qualificationsList.value = userQualifications
}

const FormError = ref<string|null>(null);

const levelOptions = [
  { value: 'self-taught', label: 'Self-taught'},
  { value: 'course', label: 'Course' },
  { value: 'certificate', label: 'Certificate' },
  { value: 'diploma', label: 'Diploma'},
  { value: 'associate-degree', label: 'Associate Degree'},
  { value: 'bachelors-degree', label: 'Bachelor\'s Degree'},
  { value: 'masters-degree', label: 'Master\'s Degree'},
  { value: 'doctorate-phd', label: 'Doctorate/Ph.D'},
  { value: 'other', label: 'Other' },
]

const newQual = ref<string>('');
const newSchool = ref<string>('');
const newLevel = ref<string>('');
const newLevelLabel = ref<string>('');

const newQualError = ref<string|null>(null);
const newSchoolError = ref<string|null>(null);
const newLevelError = ref<string|null>(null);

const submitForm = async () => {

  if (qualificationsList.value.length === 0) {
    FormError.value = 'Please select at least one sector above'
    return
  }

  FormError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      qualifications_details: JSON.stringify(qualificationsList.value)
    })
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    FormError.value = e
  }
}

const addQual = () => {
  if (newQual.value && newSchool.value && newLevel.value) {
    qualificationsList.value.push({ label: newQual.value, school: newSchool.value, level: newLevel.value, levelLabel: newLevelLabel.value})
    newQual.value = ''
    newSchool.value = ''
    newLevel.value = ''
    newLevelLabel.value = ''
  } else {
    if (!newQual.value) {
      newQualError.value = 'Please enter a qualification'
    }
    if (!newSchool.value) {
      newSchoolError.value = 'Please enter a school'
    }
    if (!newLevel.value) {
      newLevelError.value = 'Please select a level'
    }
  }
}

const removeClient = (client) => {
  qualificationsList.value = qualificationsList.value.filter((c) => c !== client)
}

watch (newLevel, (newVal) => {
  if (newVal) {
    newLevelError.value = null
    newLevelLabel.value = levelOptions.find((level) => level.value === newVal).label
  }
  if (newVal == 'self-taught') {
    newSchool.value = 'Me'
  } else if (newSchool.value == 'Me') {
    newSchool.value = ''
  }
})

watch (newSchool, (newVal) => {
  if (newVal) {
    newSchoolError.value = null
  }
})

watch (newQual, (newVal) => {
  if (newVal) {
    newQualError.value = null
  }
})

watch(qualificationsList, (newVal) => {
  if (newVal) {
    FormError.value = null
  }
})
</script>

<template>
<StepLayout 
  maxWidth="950px"
  :headline="headline">
  <template v-slot:steptip>
    <p class="text-center small text-muted pb-3 d-none">Add your credentials, from self-taught to PH.D  🤓</p>
  </template>
  <form @submit.prevent="submitForm">
    <div class="mx-auto" style="max-width: 710px;">
      <div class="d-flex flex-wrap gap-3 mb-5 justify-content-center" v-if="qualificationsList.length > 0">
        <TagQualification v-for="qual in qualificationsList" :key="qual.value" :data="qual" @click="removeClient(qual)" />
      </div>
    </div>

    <div class="d-flex flex-column gap-3 mx-auto pb-4" style="max-width:350px">

      <SelectInput
        id="level"
        label="Level"
        v-model="newLevel"
        :optionsArray="levelOptions"
        class="w-100"
        :error="newLevelError"
      />
      <TextInput
        id="school"
        label="School"
        placeholder="Eg. UCT, Wits, Udemy, etc."
        v-model="newSchool"
        @formSubmit="addQual()"
        :error="newSchoolError"
        class=""
      />

      <TextInput
        id="qualification"
        label="Qualification or Skill"
        placeholder="Eg. UX Design Planning"
        v-model="newQual"
        @formSubmit="addQual()"
        :error="newQualError"
      />

      <div class="text-end">
        <div class="btn btn-sm btn--outline-dark mt-3 ms-auto d-block d-md-inline-block w-100" :class="{'btn-hilight': newLevel && newQual && newSchool }"  @click="addQual()">Add</div>
      </div>
    </div>

    <StepFooter
      :label="buttonLabel"
      :loading="loading"
      :disabled="qualificationsList.length === 0"
      :error="FormError"
    />
  </form>
</StepLayout>
</template>