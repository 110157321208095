<script setup lang="ts">
import { ref } from 'vue'
import Headline from '@/components/wizard/Components/Headline.vue'

defineProps({
  headline: {
    type: String,
    required: false,
  },
  maxWidth: {
    type: String,
    required: false,
    default: '100%',
  },
});

const reveal = ref<boolean>(false);
</script>

<template>
  <div class="container-fluid" :class="{'reveal':reveal}">
    <div class="row" v-if="$slots.head || headline">
      <div class="col-12">
        <Headline :content="headline" v-if="headline" @finishedTyping="reveal = true" />
        <div class="space-below step_content">
          <slot name="steptip" v-if="$slots.steptip"></slot>
        </div>
        <slot name="head" v-if="$slots.head"></slot>
      </div>
    </div>

    <div class="py-4 mx-auto px-3 step_content" :style="{'max-width': maxWidth}">
      <slot></slot>
    </div>
  </div>
</template>


<style lang="scss" scoped>
  .space-below {
    min-height: 2.5vh;
  }
  .step_content {
    opacity:0;
    transform: translateY(15px);
    pointer-events: none;
    transition: opacity ease-in 0.2s 0.5s, transform ease-in-out 0.3s 0.5s;
  }
  .reveal .step_content {
    opacity:1;
    pointer-events: auto;
    transform: translateY(0);
  }
</style>