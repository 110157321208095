<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  id: {
    type: String,
    default: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
  },
  optionsArray: {
    type: Array,
    required: false,
    default: () => [],
  },
  selectedArray: {
    type: Array,
    required: false,
    default: () => [],
  },
  setMax: {
    type: [Number, String],
    default: null,
  },
  error: {
    type: String,
    default: '',
  },
})

const toggleSector = (value: object) => {
  emit('update:modelValue', value)
}

const searchTextInput = ref('')

const filteredSectors = computed(() => {
  return props.optionsArray.filter((option: { value: string; label: string }) => {
    return option.label.toLowerCase().includes(searchTextInput.value.toLowerCase())
  })
})

const createSector = () => {
  let searchSlug = searchTextInput.value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')
  emit('update:modelValue', { value: searchSlug, label: searchTextInput.value })
  searchTextInput.value = ''
}

const isSelected = (option: { value: string; label: string }) => {
  return props.selectedArray.findIndex((selected: { value: string; label: string }) => selected.value == option.value) > -1
}

onMounted(() => {});
onBeforeUnmount(() => {});

</script>

<template>
  <div class="d-flex align-items-end">
    <div class="d-flex gap-3 w-100">
      <div class="search-input">
        <input type="text" class="form-control" placeholder="Filter and add" v-model="searchTextInput" />
      </div>
      <button class="btn btn-sm btn-dark" :disabled="error" @click.prevent="createSector" v-if="filteredSectors.length == 0">Add "{{ searchTextInput }}"</button>
    </div>
    <p class="mb-0 small bubble" :class="{'bubble--filled': selectedArray.length >= setMax && setMax, 'text-muted': selectedArray.length < setMax || !setMax }">
      {{ selectedArray.length }}/{{ setMax }}
    </p>
  </div>
  <hr>
  <ul class="list-unstyled d-flex gap-1 gap-md-2 flex-wrap select-list mt-3" :class="{'listmaxed': selectedArray.length >= setMax && setMax  }">
    <li @click="toggleSector(option)" v-for="option in filteredSectors" :key="option.value" class="px-3 py-1 rounded" :class="{'selected':isSelected(option)}">
      <span>{{ option.label }}</span>
    </li>
  </ul>
</template>

<style lang="scss">
@import "@/sass/_vuestrap.scss";
.bubble {
  background-color: #f9f9f9;
  padding: 5px 10px;
  border-radius: 15px;
  &--filled {
    background-color: $secondary;
  }
}
.search-input {
    width: 250px;
    max-width: 50%
}
</style>