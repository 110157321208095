const sleepDelay = ms => new Promise(resolve => setTimeout(resolve, ms));

const triggerEvent = (name, eventDetail) => {
  const event = new CustomEvent(name, {
    detail: eventDetail
  })

  window.dispatchEvent(event)
}

export {
  sleepDelay,
  triggerEvent,
}
