<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'

import NameStep from '@/components/wizard/Steps/Names.vue'
import AvatarStep from '@/components/wizard/Steps/Avatar.vue'
import HumanTypeStep from '@/components/wizard/Steps/HumanType.vue'
import FieldExperienceStep from '@/components/wizard/Steps/FieldExperience.vue'
import PrimaryRoleStep from '@/components/wizard/Steps/PrimaryRole.vue'
import CodeStep from '@/components/wizard/Steps/Code.vue'
import FrameworkStep from '@/components/wizard/Steps/Framework.vue'
import SoftwareStep from '@/components/wizard/Steps/Software.vue'
import WorkTypeStep from '@/components/wizard/Steps/WorkType.vue'
import AvailabilityStep from '@/components/wizard/Steps/Availability.vue'
import WorkArrangementStep from '@/components/wizard/Steps/WorkArrangement.vue'
import RatesPrimaryRoleStep from '@/components/wizard/Steps/RatesPrimaryRole.vue'
import RatesSecondaryRoleStep from '@/components/wizard/Steps/RatesSecondaryRole.vue'
import ClientSectorsStep from '@/components/wizard/Steps/ClientSectors.vue'
import ClientExperienceStep from '@/components/wizard/Steps/ClientExperience.vue'
import QualificationsStep from '@/components/wizard/Steps/Qualifications.vue'
import SkillsStep from '@/components/wizard/Steps/Skills.vue'
import LinksStep from '@/components/wizard/Steps/Links.vue'
import AboutStep from '@/components/wizard/Steps/About.vue'

import { useRouter } from 'vue-router'

import { useWizardStore } from '../../stores/wizardStore';
const wizardStore = useWizardStore()

const router = useRouter()

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)
const wizardComponents = computed(() => [
  {
    slug: 'first-last-name',
    component: NameStep,
  },
  {
    slug: 'avatar',
    component: AvatarStep,
  },
  {
    slug: 'human-type',
    component: HumanTypeStep,
  },
  {
    slug: 'experience',
    component: FieldExperienceStep,
  },
  {
    slug: 'primary-role',
    component: PrimaryRoleStep,
  },
  {
    slug: 'rates-primary-role',
    component: RatesPrimaryRoleStep,
  },
  {
    slug: 'rates-secondary-role',
    component: RatesSecondaryRoleStep,
  },
  {
    slug: 'programming',
    component: CodeStep,
  },
  {
    slug: 'frameworks',
    component: FrameworkStep,
  },
  {
    slug: 'software',
    component: SoftwareStep,
  },
  {
    slug: 'work-type',
    component: WorkTypeStep,
  },
  {
    slug: 'availability',
    component: AvailabilityStep,
  },
  {
    slug: 'work-arrangement',
    component: WorkArrangementStep,
  },
  {
    slug: 'client-sectors',
    component: ClientSectorsStep,
  },
  {
    slug: 'client-experience',
    component: ClientExperienceStep,
  },
  {
    slug: 'qualifications',
    component: QualificationsStep,
  },
  {
    slug: 'skills',
    component: SkillsStep,
  },
  {
    slug: 'links',
    component: LinksStep,
  },
  {
    slug: 'about',
    component: AboutStep,
  }
])

const currentStep = computed(() => wizardStore.currentStep)
const currentStepComponent = computed(() => wizardComponents.value.find((component) => component.slug === currentStep.value?.slug))
const removedSignupComponents = computed(() => [])

const showWizard = computed(() => {
  // console.log()

  return !router.currentRoute.value.meta.hideWizard && wizardStore.showWizard
})

const removeComponent = (slug: string) => {
  const index = wizardComponents.value.findIndex((component) => component.slug === slug)
  removedSignupComponents.value.push(wizardComponents.value[index])
  if (index > -1) {
    wizardComponents.value.splice(index, 1)
  }
}
//add component if not already in the list
const addComponent = (slug: string, order: number) => {
  const index = removedSignupComponents.value.findIndex((component) => component.slug === slug)
  if (index > -1) {
    wizardComponents.value.splice(order, 0, removedSignupComponents.value[index])
    removedSignupComponents.value.splice(index, 1)
  }
}

const toggleBodyScroll = (enable: boolean) => {
  document.body.style.overflow = enable ? 'auto' : 'hidden'
}

const progressStats = computed(() => wizardStore.progressStats)

onMounted(() => {
  toggleBodyScroll(!showWizard.value)
})

watch(() => showWizard.value, (newValue: boolean) => {
  toggleBodyScroll(!newValue)
})
</script>

<template>
  <div class="wiz__container d-flex flex-column" v-if="showWizard">
    <div class="wiz__header d-flex justify-content-center align-items-center flex-grow-0 flex-shrink-0">
      
      <img src="@/assets/images/ss-logo.png" class="wiz__logo">

        <a href="https://api.whatsapp.com/send/?phone=27795820675" target="_blank" class="help-link">
          <span class="icon material-symbols-outlined">
            chat
          </span>
          <span class="d-none d-md-block">I'm stuck, help!</span>
          <span class="d-md-none">Help</span>
        </a>
      
    </div>
    <div class="w-100">
      <div class="wiz__container-inner">
        <div class="wiz__body flex-grow-1">

          <component :is="currentStepComponent?.component" 
            @removeComponent="removeComponent"
            @addComponent="addComponent" />
  
        </div>
      </div>
      <div class="progressBar text-center" v-if="!editMode">
        <div class="progressBar__yellow" :style="{ width: progressStats.width }"></div>
        <div class="progressBar__inner fw-600">
          {{ progressStats.current + 1 }} of {{ progressStats.total }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.help-link {
  position: absolute;
  right: 10px;
  top:8px;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  .icon {
    margin-right: 5px;
    font-size: 24px;
    border-radius: 10px;
  }
  &:hover {
    .icon {
      background-color: #FFFF00;
    }
  }
}
.progressBar {
  font-size: 14px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #efefef;
  padding:5px 10px;
  &__yellow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color:#FFFF00;
    transition: width 0.4s ease-out;
  }
  &__inner {
    position:relative;
  }
}
.wiz {
  &__container {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background-color: #fff;
    &-inner {
      min-height: calc(100vh - 115px);
    }
  }
  &__header {
    padding: 20px;
    padding-bottom: 0;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      height: 65%;
      background-color: #efefef;
      z-index: -1;
    }
  }
  &__back {
    position: absolute;
    top: 0;
    left: 0;
    height: 65%;
    &_btn {
      font-size: 24px;
      line-height: 1;

      padding: 5px;
      background-color: rgb(255, 255, 255); 
      border-radius: 5px;

      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;

      &.show {
        transition: opacity 0.2s 0.3s;
        opacity: 0.6;
        pointer-events: all;
        &:hover {
          opacity: 1;
        }
      }
    }


  }
  &__logo {
    width: 65px;
  }
  &__body {

    // position: absolute;
    // top: 50px;
    // left: 0;
    // width: 100%;
    // height: calc(100% - 50px);
    // overflow-y: auto;
    // padding: 10px;
    // display: flex;
    // flex-direction: column;
    // justify-content: start;
    // align-items: center;
    // overflow-y: auto;
    // .row {
    //   max-width: 650px;
    //   width: 100%;
    // }
  }
}
</style>