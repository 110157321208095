<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TagSelector from '@/components/wizard/Components/TagSelector.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'
import { useRoleStore } from '@/stores/roleStore'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update you list of skills and strengths'
  } else {
    return 'What are your greatest skills and strengths?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const selectedSkills = ref<Array<object>>([]);
const userSkills = useUserStore().userComputed.skills_details

if (userSkills) {
  selectedSkills.value = userSkills
}

const SkillsError = ref<string|null>(null);
const Skills = ref([
  { value: '', label: '' },
])
const dataSoft = useRoleStore().getAllSkills

if (dataSoft) {
  Skills.value = dataSoft
}

const mergedSkills = computed(() => {
  //combine the two arrays and remove duplicates
  let combined = [...Skills.value, ...selectedSkills.value].filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.value === item.value
    ))
  )
  return combined.sort((a, b) => a.label.localeCompare(b.label))
})

const submitForm = async () => {
  if (selectedSkills.value.length === 0) {
    SkillsError.value = 'Please select at least one skill or strength above'
    return
  }

  SkillsError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      skills_details: JSON.stringify(selectedSkills.value)
    })
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    SkillsError.value = e
  }
}

const toggleClientLanguage = (selectedSkill: object) => {
  let selected = selectedSkills.value.findIndex((selectedRoles) => selectedRoles.value == selectedSkill.value) > -1
  if (selected) {
    removeLanguage(selectedSkill)
  } else {
    if (selectedSkills.value.length >= 6) {
      SkillsError.value = 'You can select a maximum of six skills.'
      return
    }
    selectedSkills.value.push(selectedSkill)
  }
}

const removeLanguage = (language: object) => {
  selectedSkills.value = selectedSkills.value.filter((selected) => {
    return selected.value !== language.value
  })
}

watch(selectedSkills, (newVal) => {
  if (newVal) {
    SkillsError.value = null
  }
})
</script>

<template>
<StepLayout 
  maxWidth="850px"
  :headline="headline">
  <template v-slot:steptip>
    <p class="text-center small text-muted pb-3">Choose up to six from the list below or filter and add your own.</p>
  </template>

  <form @submit.prevent="submitForm">
    <TagSelector
      setMax="6"
      :optionsArray="mergedSkills"
      :selectedArray="selectedSkills"
      :error="SkillsError"
      @update:modelValue="toggleClientLanguage($event)"
    />

    <StepFooter :error="SkillsError" :loading="loading" :label="buttonLabel" />
  </form>
</StepLayout>
</template>