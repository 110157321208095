<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TagSelector from '@/components/wizard/Components/TagSelector.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'
import { useRoleStore } from '@/stores/roleStore'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);


const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update the list of frameworks and services you are comfortable with'
  } else {
    return 'Which frameworks and services are you comfortable with?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const selectedFrameworks = ref<Array<object>>([]);
const userFramework = useUserStore().userComputed.framework_details
if (userFramework) {
  selectedFrameworks.value = userFramework
}

const selectedcode = ref<Array<object>>([]);
const userCode = useUserStore().userComputed.programming_details
if (userCode) {
  // flatten the selectedCode value to and array of the userCode values
  selectedcode.value = userCode.map((code: any) => {
    return code.value
  })
}

const FrameworkError = ref<string|null>(null);
const Frameworks = ref([
  { value: '', label: '' },
])
const dataFrameworks = useRoleStore().getFrameworks
const generalFrameworks = useRoleStore().getGeneralCloud

const filteredFrameworks = computed(() => {
  // filter out the selected frameworks by matching the code value to the slug value
  let matchingFrameworks = dataFrameworks.filter((framework) => {
    return selectedcode.value.includes(framework.slug)
  })
  //combine all of the .frameworks values into a single array
  let combinedFrameworks = matchingFrameworks.map((framework) => {
    return framework.frameworks
  }).flat()

  //add the general frameworks to the combinedFrameworks array
  combinedFrameworks = [...combinedFrameworks, ...generalFrameworks]

  return combinedFrameworks
})

const mergedFramework = computed(() => {
  //combine the two arrays and remove duplicates
  let combined = [...filteredFrameworks.value, ...selectedFrameworks.value].filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.value === item.value
    ))
  )
  return combined.sort((a, b) => a.label.localeCompare(b.label))
})

const submitForm = async () => {
  if (selectedFrameworks.value.length === 0) {
    FrameworkError.value = 'Please select at least one language above'
    return
  }

  FrameworkError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      framework_details: JSON.stringify(selectedFrameworks.value)
    })
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    FrameworkError.value = e
  }
}

const toggleClientLanguage = (selectedLanguage: object) => {
  let selected = selectedFrameworks.value.findIndex((selectedRoles) => selectedRoles.value == selectedLanguage.value) > -1
  if (selected) {
    removeLanguage(selectedLanguage)
  } else {
    if (selectedFrameworks.value.length >= 500) {
      FrameworkError.value = 'You can only select up to five hundred languages.'
      return
    }
    selectedFrameworks.value.push(selectedLanguage)
  }
}

const removeLanguage = (language: object) => {
  selectedFrameworks.value = selectedFrameworks.value.filter((selected) => {
    return selected.value !== language.value
  })
}

watch(selectedFrameworks, (newVal) => {
  if (newVal) {
    FrameworkError.value = null
  }
})
</script>

<template>
<StepLayout 
  maxWidth="850px"
  :headline="headline">
  <template v-slot:steptip>
    <p class="text-center small text-muted pb-3">Select from the list below or filter and add your own.</p>
  </template>
  <form @submit.prevent="submitForm">
    <TagSelector
      setMax="20"
      :optionsArray="mergedFramework"
      :selectedArray="selectedFrameworks"
      :error="FrameworkError"
      @update:modelValue="toggleClientLanguage($event)"
    />

    <StepFooter :error="FrameworkError" :loading="loading" :label="buttonLabel" />
  </form>
</StepLayout>
</template>