<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import SelectInput from '@/components/form/SelectInput.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const userRates = useUserStore().userComputed.primary_rates
const userRole = useUserStore().userComputed.primary_details

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update the rates you charge for your primary role'
  } else {
    return 'How much do you charge for your primary role?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const loading = ref<boolean>(false);

const hourlyLow = ref<Number>(500);
const hourlyHigh = ref<Number>(750);

const hourlyRate = ref<string>('');
const hourlyError = ref<string|null>(null);
const hourlyOptions: { value: string, label: string }[] = [];

const dailyRate = ref<string>('');
const dailyError = ref<string|null>(null);
const dailyOptions: { value: string, label: string }[] = [];

const monthlyRate = ref<string>('');
const monthlyError = ref<string|null>(null);
const monthlyOptions: { value: string, label: string }[] = [];

if(userRates) {
  hourlyRate.value = userRates.hourly_rate
  dailyRate.value = userRates.daily_rate
  monthlyRate.value = userRates.monthly_rate
}

const submitForm = async () => {
  if (!hourlyRate.value) {
    hourlyError.value = 'Please select an option'
  }
  if (!dailyRate.value) {
    dailyError.value = 'Please select an option'
  }
  if (!monthlyRate.value) {
    monthlyError.value = 'Please select an option'
  }
  if(hourlyError.value || dailyError.value || monthlyError.value) {
    return
  }

  hourlyError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      primary_rates: JSON.stringify({
        hourly_rate: hourlyRate.value,
        daily_rate: dailyRate.value,
        monthly_rate: monthlyRate.value
      }),
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

const populateTimeOptions = () => {
  hourlyOptions.length = 0;
  dailyOptions.length = 0;
  monthlyOptions.length = 0;

  // dailyRate.value = '';
  // monthlyRate.value = '';

  //use the hourly rate in R50 increments, start R150  and go R1250
  for (let i = 100; i <= 1250; i += 50) {
    hourlyOptions.push({ value: i.toString(), label: `R${kFormat(i)}/hour` })
  }

  // take the selected hourlyRate.value and 4 lower values from hourlyOptions and multiply them by 8 to populate the daily rates
  if(hourlyRate.value) {
    const hourlyRateValue = parseInt(hourlyRate.value)
    for (let i = Math.max(hourlyRateValue - 200, 100); i <= hourlyRateValue; i += 25) {
      const dRate = Math.round(i * 8 / 200) * 200;
      dailyOptions.push({ value: dRate.toString(), label: `R${kFormat(dRate)}/day` })
    }
    dailyOptions.sort((a, b) => parseInt(b.value) - parseInt(a.value))
  }

  // take the selected hourlyRate.value and 4 lower values from hourlyOptions and multiply them by 160 to populate the monthly rates
  if(hourlyRate.value) {
    const hourlyRateValue = parseInt(hourlyRate.value)
    for (let i = Math.max(hourlyRateValue - 400, 100); i <= hourlyRateValue; i += 25) {
      const mRate = Math.round(i * 160 / 2000) * 2000;
      monthlyOptions.push({ value: mRate.toString(), label: `R${kFormat(mRate)}/month` })
    }
    monthlyOptions.sort((a, b) => parseInt(b.value) - parseInt(a.value))
  }
  
}

const kFormat = (num: number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

watch(hourlyRate, (newVal) => {
  if (newVal) {
    hourlyError.value = null
    populateTimeOptions()
  }
})
watch(dailyRate, (newVal) => {
  if (newVal) {
    dailyError.value = null
  }
})
watch(monthlyRate, (newVal) => {
  if (newVal) {
    monthlyError.value = null
  }
})

onMounted(() => {
  populateTimeOptions()
})

</script>

<template>
<StepLayout 
  maxWidth="550px"
  :headline="headline">

  <template v-slot:steptip>
      <p class="text-center small text-muted pb-3">🥷 Your rates are only visible to potential clients.</p>
  </template>
  
  <h4 class="h5 mb-4"><span class="fw-600">{{ userRole?.roleName }}</span> <span class="fw-400 small">({{ userRole?.level }} level)</span></h4>
  <form @submit.prevent="submitForm">
    <div class="mx-auto" style="max-width: 900px;">
      <SelectInput
      id="hourlyRate"
      label="Hourly rate"
      inlineLabel
      v-model="hourlyRate"
      :optionsArray="hourlyOptions"
      :error="hourlyError" />
    </div>
    <div class="pb-4 pt-1 text-sm-end">
      <span class="small text-muted">
        Example: R{{ kFormat(hourlyLow) }} – R{{ kFormat(hourlyHigh) }}/hour
      </span>
    </div>

    <div class="mx-auto" style="max-width: 900px;">
      <SelectInput
      id="dailyRate"
      label="Daily rate"
      inlineLabel
      v-model="dailyRate"
      :optionsArray="dailyOptions"
      :disabled="!hourlyRate"
      :error="dailyError" />
    </div>
    <div class="pb-4 pt-1 text-sm-end">
      <span class="small text-muted" v-if="hourlyRate">
        Suggested: R{{ kFormat((hourlyRate - 50) * 8) }} – R{{ kFormat(hourlyRate * 8) }}/day
      </span>
    </div>

    <div class="mx-auto" style="max-width: 900px;">
      <SelectInput
      id="monthlyRate"
      label="Monthly rate"
      inlineLabel
      v-model="monthlyRate"
      :optionsArray="monthlyOptions"
      :disabled="!hourlyRate"
      :error="monthlyError" />
    </div>
    <div class="pb-4 pt-1 text-sm-end">
      <span class="small text-muted" v-if="hourlyRate">
        Suggested: R{{ kFormat((hourlyRate - 50) * 160) }} – R{{ kFormat(hourlyRate * 160) }}/month
      </span>
    </div>
  
    <StepFooter :loading="loading" :label="buttonLabel" />
  </form>
</StepLayout>
</template>

<!-- Todo: Make suggested rates clickable -->
