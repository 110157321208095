<script setup lang="ts">
import Button from '@/components/form/Button.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
const wizardStore = useWizardStore()

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: "Continue",
  },
});


const backStep = () => {
  wizardStore.backStep()
}
</script>

<template>
  <div class="text-center mt-5">
    <div class="space-above"></div>
    <p v-if="error" class="error mt-2 text-danger small">{{ error }}</p>
    <div class="d-flex justify-content-center w-100 gap-3">
      <a href="#" @click.prevent="backStep()" class="btn btn-back flex-grow-0" :class="{'d-none': wizardStore.currentStepIndex == 0}">
        <i class="icon-arrow-left"></i>
      </a>

      <Button 
        type="submit" 
        :loading="loading"
        :disabled="disabled"
        class="btn-dark px-5 px-sm-6">
          <span v-html="label"></span>
        </Button>

        <div class="spacer-50" :class="{'d-sm-block': wizardStore.currentStepIndex > 0}"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .space-above {
    min-height: 3vh;
  }
  .btn-back {
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 5px;
    width: 50px;
    color: #888;
    font-size: 1.4rem;
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s, color 0.2s;
    &:hover {
      background-color: #FFFF00;
      color: #000;
      border-color: #000;;
    }
  }
  .spacer-50 {
    display: none;
    width: 50px;
  }
</style>
