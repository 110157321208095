<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TagSelector from '@/components/wizard/Components/TagSelector.vue'
import TagText from '@/components/wizard/Components/TagText.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);


const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update the client sectors you\'ve worked in'
  } else {
    return 'Which client sectors have you worked in?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const selectedClientSectors = ref<Array<object>>([]);
const userClientSectors = useUserStore().userComputed.sector_details

if (userClientSectors) {
  selectedClientSectors.value = userClientSectors
}

const ClientSectorsError = ref<string|null>(null);
const ClientSectors = ref([
    { "label": "Technology", "value": "technology" },
    { "label": "Healthcare", "value": "healthcare" },
    { "label": "Finance", "value": "finance" },
    { "label": "Government", "value": "government" },
    { "label": "Retail", "value": "retail" },
    { "label": "Manufacturing", "value": "manufacturing" },
    { "label": "Education", "value": "education" },
    { "label": "Hospitality and Tourism", "value": "hospitality-and-tourism" },
    { "label": "Energy", "value": "energy" },
    { "label": "Real Estate", "value": "real-estate" },
    { "label": "Entertainment and Media", "value": "entertainment-and-media" },
    { "label": "Transportation", "value": "transportation" },
    { "label": "Blockchain", "value": "blockchain" },
    { "label": "Fintech", "value": "fintech" }

])

const mergedClientSectors = computed(() => {
  //combine the two arrays and remove duplicates
  let combined = [...ClientSectors.value, ...selectedClientSectors.value].filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.value === item.value
    ))
  )
  return combined.sort((a, b) => a.label.localeCompare(b.label))
})

const submitForm = async () => {
  if (selectedClientSectors.value.length === 0) {
    ClientSectorsError.value = 'Please select at least one sector above'
    return
  }

  ClientSectorsError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      sector_details: JSON.stringify(selectedClientSectors.value)
    })
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    ClientSectorsError.value = e
  }
}

const toggleClientSector = (selectedSector: object) => {
  let selected = selectedClientSectors.value.findIndex((selectedRoles) => selectedRoles.value == selectedSector.value) > -1
  if (selected) {
    removeSector(selectedSector)
  } else {
    if (selectedClientSectors.value.length >= 5) {
      ClientSectorsError.value = 'You can only select up to five sectors'
      return
    }
    selectedClientSectors.value.push(selectedSector)
  }
}

const removeSector = (sector: object) => {
  selectedClientSectors.value = selectedClientSectors.value.filter((selected) => {
    return selected.value !== sector.value
  })
}

watch(selectedClientSectors, (newVal) => {
  if (newVal) {
    ClientSectorsError.value = null
  }
})
</script>

<template>
<StepLayout 
  maxWidth="850px"
  :headline="headline">
  <template v-slot:steptip>
    <p class="text-center small text-muted pb-3">Select up to five industries from the list below or filter and add your own</p>
  </template>

  <form @submit.prevent="submitForm">
    <TagSelector
      :optionsArray="mergedClientSectors"
      setMax="5"
      :selectedArray="selectedClientSectors"
      :error="ClientSectorsError"
      @update:modelValue="toggleClientSector($event)"
    />

    <StepFooter :error="ClientSectorsError" :loading="loading" :label="buttonLabel" />
  </form>
</StepLayout>
</template>