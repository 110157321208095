<script setup lang="ts">
import { ref, watch } from 'vue'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  id: {
    type: String,
    default: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
  },
  label: {
    type: String,
    required: false,
  },
  inlineLabel: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
  error: {
    type: String,
    default: '',
  },
})

const toggleValue = ref(props.modelValue)

watch(() => props.modelValue, (newValue) => {
  toggleValue.value = newValue
})

</script>

<template>
  <div>
    <div>
      <input 
        type="checkbox"
        :id="id"
        class="form-checkbox hideInput"
        :name="id"
        :true-value="true"
        :false-value="false"
        v-model="toggleValue" 
        :class="{'is-invalid': error}"
        @change="$emit('update:modelValue', toggleValue)" />

      

      <label 
        :for="id" 
        class="form-label d-flex gap-3">
        <span class="customCheckbox" :class="{'checked': toggleValue}"></span>

        <span 
          v-html="label" 
          v-if="label"></span>
      </label>
    </div>

    <p v-if="error" class="error mt-2 text-danger small">{{ error }}</p>
  </div>
</template>

<style lang="scss">
@import "@/sass/_vuestrap.scss";
.hideInput {
  position: absolute;
  height: 1px;
  width: 1px;
  left: -9999px;
  top: -9999px;
  opacity: 0.01;
}

.customCheckbox {
  display: block;
  height: 34px;
  width: 34px;
  border-radius: 6px;
  background-color: #FFF;
  border: 2px solid #666;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    display: block;
    height: 35%;
    width: 70%;
    border-left: 3px solid #000000;
    border-bottom: 3px solid #000000;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(0.7);
    opacity: 0;
    transition: opacity 0.1s ease-out, transform 0.1s ease-in;
  }

  &.checked {
    background-color: #FFFF00;
    border-color: #333;
    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(1);
      opacity: 1;
    }
  }
}
</style>