<script setup lang="ts">
import { ref } from 'vue'

import ModalComponent from '@/components/ModalComponent.vue'
import Button from '@/components/form/Button.vue'
import SelectInput from '@/components/form/SelectInput.vue'
import CheckboxInput from '@/components/form/CheckboxInput.vue'


const props = defineProps({
  modalData: {
    type: Object as () => object|null,
    required: true,
  }
});

// page variables
const emit = defineEmits(['closeModal'])
const error = ref<string|null>(null);

const minLength = ref<number|null>(null)
const maxLength = ref<number|null>(null)
const agree = ref<boolean>(false)

if (props.modalData) {
  minLength.value = props.modalData.minLength
  maxLength.value = props.modalData.maxLength
  agree.value = props.modalData.agree
}

const lengthOptions = [
  { 
    value: -1, 
    label: "Any" 
  },
  { 
    value: 1, 
    label: "1 month" 
  },
  { 
    value: 3, 
    label: "3 months" 
  },
  { 
    value: 6, 
    label: "6 months" 
  },
  { 
    value: 12, 
    label: "12 months" 
  },
]

const closeModal = (submit: boolean = false) => {
  let submitData = false
  error.value = null

  if (submit) {
    if (!minLength.value) {
      error.value = 'Please select a minimum length'
    } else if (!maxLength.value) {
      error.value = 'Please select a maximum length'
    } else if (!agree.value) {
      error.value = 'Please agree to the terms'
    }

    if (error.value) return;

    submitData = {
      minLength: minLength.value,
      maxLength: maxLength.value,
      agree: agree.value,
    }
  }

  emit('closeModal', submitData)
}

const toggleAgree = () => {
  agree.value = !agree.value
}
</script>

<template>
  <ModalComponent 
    showCloser 
    maxWidth="730px"
    @close="closeModal()">

    <div class="mb-4">
      <h3 class="mb-4">Fixed-term work</h3>
      <p class="m-0">A fixed-term contract requires a full-time commitment for the length of the contract. If that's for you, please continue below:</p>
    </div>

    <form @submit.prevent="closeModal(true)">
      <div class="inputGroup">
        <p class="fw-bold mb-0">Preferred contract length</p>
        <p>Select the minimum and maximum contract length you want to work on:</p>

        <div class="d-flex flex-column flex-md-row gap-3">
          <div class="w-100">
            <SelectInput
              id="minLength"
              placeholder="Minimum length"
              v-model="minLength"
              :optionsArray="lengthOptions"
              fullWidth />
          </div>
          
          <div class="w-100">
            <SelectInput
              id="maxLength"
              placeholder="Maximum length"
              v-model="maxLength"
              :optionsArray="lengthOptions"
              fullWidth />
          </div>
        </div>
      </div>

      <div class="inputGroup">
        <p class="fw-bold mb-3">Please read and accept</p>
        
        <div class="d-flex gap-3">
          <CheckboxInput 
            id="agree"
            v-model="agree" />
          <p class="mb-0" @click.prevent="toggleAgree">A fixed term contract means you're on board for a set time at a specific rate. You are exclusively working with one client without juggling others during the contract. Keep it focused, no moonlighting or side gigs allowed!</p>
        </div>
      </div>

      <div class="text-center mt-4 mb-n4">
        <p class="text-danger" v-if="error">{{ error }}</p>
        <Button type="submit" class="btn-dark px-6">Continue</Button>
      </div>      
    </form>

  </ModalComponent>
</template>