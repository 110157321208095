<script setup lang="ts">
import { ref } from 'vue'

import ModalComponent from '@/components/ModalComponent.vue'
import Button from '@/components/form/Button.vue'
import CheckboxInput from '@/components/form/CheckboxInput.vue'


const props = defineProps({
  modalData: {
    type: Object as () => object|null,
    required: true,
  }
});

// page variables
const emit = defineEmits(['closeModal'])
const error = ref<string|null>(null);

const agree = ref<boolean>(false)
if (props.modalData) {
  agree.value = props.modalData.agree
}

const toggleAgree = () => {
  agree.value = !agree.value
}

const closeModal = (submit: boolean = false) => {
  let submitData = false
  error.value = null

  if (submit) {
    submitData = {
      agree: agree.value,
    }
  }

  emit('closeModal', submitData)
}
</script>

<template>
  <ModalComponent 
    showCloser 
    maxWidth="730px"
    @close="closeModal()">
  
    <div class="mb-4">
      <h3 class="mb-4">Do you want Overflow work?</h3>
      <p class="m-0">Build your network and income streams by making yourself available to other designers on Secretsource to take on overflow work.</p>
    </div>
  
    <form @submit.prevent="closeModal(true)">
  
      <div class="inputGroup">
        <p class="fw-bold mb-3">I am available for Overflow work (optional)</p>
        
        <div class="d-flex gap-3">
          <CheckboxInput
            id="agree" 
            v-model="agree" />
          <p class="mb-0" @click.prevent="toggleAgree">Overflow work involves stepping in to help fellow creatives who are maxed out and could use an extra set of hands. It usually means working under their brand as a white-label resource.</p>
        </div>
      </div>
  
      <div class="text-center mt-4 mb-n4">
        <p class="text-danger" v-if="error">{{ error }}</p>
        <Button type="submit" class="btn-dark px-6">Continue</Button>
      </div>      
    </form>
  
  </ModalComponent>
</template>