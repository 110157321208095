import './style.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Rollbar from 'rollbar';

import App from './App.vue'
import router from './router'

import "@/plugins/axios";

const rollbar = new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: import.meta.env.VITE_ROLLBAR_ENABLED == 'true',
  captureUnhandledRejections: import.meta.env.VITE_ROLLBAR_ENABLED == 'true',
  payload: {
    environment: process.env.NODE_ENV || 'production' || 'development',
  },
});

const app = createApp(App)

app.use(createPinia())
app.use(router)

if (import.meta.env.VITE_ROLLBAR_ENABLED == 'true') {
  app.config.errorHandler = (err, vm, info) => {
    rollbar.error(err);
    throw err; // rethrow
  };
  app.config.globalProperties.$rollbar = rollbar;
}

app.mount('#app')
