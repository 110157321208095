<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

const props = defineProps({
  content: {
    type: String,
    required: true,
  }
});

const emit = defineEmits(['finishedTyping']);


const wordArray = props.content.split(' ');
const writtenSentance = ref<string>('');
const finishedTyping = ref<boolean>(false);

const writeWords = () => {
  let i = 0;
  const interval = setInterval(() => {
    if (i < wordArray.length) {
      writtenSentance.value += wordArray[i] + ' ';
      i++;
    } else {
      clearInterval(interval);
      finishedTyping.value = true;
    }
  }, 50);
}

onMounted(() => {
  setTimeout(() => {
    writeWords();
  }, 500);
});

watch(finishedTyping, (newValue) => {
  if (newValue) {
    emit('finishedTyping');
  }
});

</script>

<template>
<div class="text-center" v-if="content">
    <h5 class="headline my-4 position-relative mx-3">
      <span class="headline--back" v-html="content"></span>
      <span class="headline--over" v-html="writtenSentance"></span>
    </h5>
</div>
</template>


<style lang="scss" scoped>
  .headline {
    display: inline-block;
    font-weight: 500;
    background-color: #efefef;
    color: #555;
    display: inline-block;
    border-radius: 15px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-bottom: 15px solid #efefef;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      transform: translateX(-50%);
    }
    span {
      text-align: left;
      display: block;
      padding: 15px 30px;
    }
    &--back {
      opacity:0;
    }
    &--over {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
</style>
