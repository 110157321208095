<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'
import Button from '@/components/form/Button.vue';

import AboutModal from '@/components/wizard/Modals/About_AI.vue'

import { useUserStore } from '@/stores/userStore.ts'
import { useWizardStore } from '@/stores/wizardStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);
const error = ref<string|null>(null);


const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update your intro paragraph'
  } else {
    return 'Tell the world a bit about yourself with a short bio'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Final Final 😉'
  }
})
 
const modalToShow = ref<string|null>(null);
const ai_modalData = ref<object|null>(null);
const aiOptions = ref<array>([]);
const selectedAIindex = ref<number>(0);

const aboutInfo = ref<string|null>(null);
const charLimit = ref<number>(500);
const charRemaining = computed (() => {
  if (aboutInfo.value) {
    return charLimit.value - aboutInfo.value.length
  }
  return charLimit.value
})

const first_name = useUserStore().userComputed.first_name
const userAbout = useUserStore().userComputed.about
if (userAbout) {
  aboutInfo.value = userAbout
}

const placeHolderText = "Hi, I'm " + first_name + " and I'm a ..."


const modalClose = (submitData: any) => {
  error.value = null;
  if (submitData) {
    ai_modalData.value = submitData;
    getGptOptions()
  }
  modalToShow.value = null;
}

const submitForm = async () => {
  if (!aboutInfo.value) {
    error.value = "Say anything, tell us something about yourself above"
    return;
  }

  error.value = null
  loading.value = true;
  try {
    await useUserStore().updateUser({
      about: `${ aboutInfo.value }`
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

const getGptOptions = async () => {
  loading.value = true;
  try {
    const gptOptions = await useWizardStore().getAboutGPT(ai_modalData.value.afterWork, ai_modalData.value.passions)
    if (gptOptions) {
      aiOptions.value = gptOptions
      aboutInfo.value = gptOptions[0]
      selectedAIindex.value = 0
    }

    loading.value = false
  } catch (e) {
    error.value = e
  }
}

const incAi = (inc: number) => {
  if (selectedAIindex.value + inc >= 0 && selectedAIindex.value + inc < aiOptions.value.length) {
    selectedAIindex.value += inc
    aboutInfo.value = aiOptions.value[selectedAIindex.value]
  }
}

watch(aboutInfo, (newValue) => {
  if (newValue) {
    if(aiOptions.value.length > 0) {
      aiOptions.value[selectedAIindex.value] = newValue
    }
    error.value = null
  }
})
</script>

<template>
  <StepLayout :headline="headline">
  <template v-slot:steptip>
    <p v-if="aiOptions.length > 0" class="text-center small text-muted pb-3">Choose one of the 3 examples. <a href="#" :class="{'disabled': loading}" class="small-link" @click.prevent="modalToShow = 'ai' ">Or try again</a> 🌟.</p>
  </template>

  <AboutModal 
    :modalData="ai_modalData"
    v-if="modalToShow == 'ai'" 
    @closeModal="modalClose"/>

    <form @submit.prevent="submitForm">
      <div class="d-flex gap-3 flex-wrap mx-auto justify-content-center" style="max-width: 840px">
        <div class="text-center w-100" v-if="aiOptions.length == 0">
          <Button @click.prevent="modalToShow = 'ai'" class="btn btn-secondary btn-sm px-4"><svg xmlns="http://www.w3.org/2000/svg" class="icon ms-n1 me-2 mt-n1" viewBox="0 0 512 512"><path d="M259.92 262.91L216.4 149.77a9 9 0 00-16.8 0l-43.52 113.14a9 9 0 01-5.17 5.17L37.77 311.6a9 9 0 000 16.8l113.14 43.52a9 9 0 015.17 5.17l43.52 113.14a9 9 0 0016.8 0l43.52-113.14a9 9 0 015.17-5.17l113.14-43.52a9 9 0 000-16.8l-113.14-43.52a9 9 0 01-5.17-5.17zM108 68L88 16 68 68 16 88l52 20 20 52 20-52 52-20-52-20zM426.67 117.33L400 48l-26.67 69.33L304 144l69.33 26.67L400 240l26.67-69.33L496 144l-69.33-26.67z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg> Use AI</Button>
        </div>
        <textarea name="" id="" class="w-100 aboutText" :placeholder="placeHolderText" v-model="aboutInfo"></textarea>
        <div class="w-100 d-flex align-items-center justify-content-between">
          <p class="small mt-n1 ms-1" :class="{'text-danger fw-bold': charRemaining < 0, 'text-muted': charRemaining >= 0}">Characters remaining: {{ charRemaining }}</p>
          <div v-if="aiOptions.length > 0" class="d-flex gap-2 align-items-center">
            <p class="currentIndex_prev text-muted small"  @click.prevent="incAi(-1)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
            </p>
            <p class="currentIndex text-muted small">{{ selectedAIindex + 1 }}/3</p>
            <p class="currentIndex_next text-muted small"  @click.prevent="incAi(1)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </p>
          </div>
          <div v-else></div>
        </div>
      </div>
      
      <StepFooter
        :label="buttonLabel"
        :error="error" 
        :disabled="!aboutInfo || aboutInfo.length > 500"
        :loading="loading" />
    </form>
  </StepLayout>
</template>

<style lang="scss" scoped>

svg.icon {
  width: 20px;
}
.currentIndex {
  &_next, &_prev {
    border-radius: 4px;
    svg {
      opacity:0.5;
      width: 20px;
    }
    cursor: pointer;
    &:hover {
      svg {
        opacity:1;
      }
      background-color: #FFFF00;
    }
  }
}
.aboutText {
  height: 200px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 15px 20px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  resize: none;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
}

.disabled {
  pointer-events: none;
}
</style>
