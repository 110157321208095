<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const removeSector = (value: object) => {
  emit('update:modelValue', value)
}

const widont = (str: string) => {
  if (str.split(' ').length > 2) {
    return str.replace(/\s(?=[^\s]*$)/g, '&nbsp;')
  } else {
    return str
  }
}

const first2Char = (str: string) => {
  str = str.replace(/-/g, ' ')
  return str.substring(0, 2)
}

onMounted(() => {});
onBeforeUnmount(() => {});

</script>

<template>
  <div class="tag-text d-flex align-items-center">
    <div class="tag-av">
      <span class="material-symbols-outlined">
        rewarded_ads
      </span>
    </div>
    <div>
      <div class="tag-label"><span v-html="widont(data.label)"></span></div>
      <div class="smallweb">{{ data.website }}</div>
    </div>
    <div class="close-tag">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/sass/_vuestrap.scss";
.smallweb {
  font-size: 11px;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
}
.tag-av span {
  font-size: 2em!important;
  opacity: 0.8;
}
</style>