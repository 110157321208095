<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  id: {
    type: String,
    default: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
  },
  label: {
    type: String,
    required: false,
  },
  inlineLabel: {
    type: Boolean,
    default: false,
  },
  returnIndex: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  optionsArray: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Select',
  },
  minWidth: {
    type: String,
    default: '210px',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: '',
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  optional: {
    type: Boolean,
    default: false,
  },
})

const dropdown = ref();
const dropdowncontent = ref();
const isOpen = ref(false);
const selected = ref(null);

const canScrollUp = ref(false);
const canScrollDown = ref(false);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;

  if (isOpen.value) {
    checkScroll()
      setTimeout(() => {
    }, 200);
  }
};

const selectOption = (selectedIndex: number, selectedItem: object) => {

  emit('update:modelValue', props.returnIndex ? selectedIndex : selectedItem.value)
  selected.value = selectedItem.label

  isOpen.value = false
};

const handleClickOutside = (event: Event) => {
  if (dropdown.value && !dropdown.value.contains(event.target)) {
    isOpen.value = false;
  }
};

const checkScroll = () => {
  const buffer = 5;
  const dropCon = dropdowncontent.value;
  
  if (dropCon) {
    const scrollTop = dropCon.scrollTop; // height from top
    const scrollHeight = dropCon.scrollHeight; // height of entire content
    const clientHeight = dropCon.clientHeight; // height of visible content
  
    if (scrollTop) {
      if (scrollTop > buffer) {
        canScrollUp.value = true;
      } else {
        canScrollUp.value = false;
      }
    
      if (scrollTop + clientHeight < (scrollHeight - buffer)) {
        canScrollDown.value = true;
      } else {
        canScrollDown.value = false;
      }
    }
  }
};

const handleModelChange = (newValue) => {
  if (newValue) {
    const selectedOption = props.optionsArray.find((option: any) => option.value === newValue);
    if (selectedOption) {
      selected.value = selectedOption.label
    }

  } else {
    selected.value = null

  }
};

const setInitialValue = () => {
  if (props.modelValue) {
    const selectedOption = props.optionsArray.find((option: any) => option.value == props.modelValue);
    if (selectedOption) {
      selected.value = selectedOption.label
    }
  }
};

onMounted(() => {
  //setInitialValue() to update the selected value when the component is mounted before the modelValue is updated;
  setInitialValue();
  setTimeout(() => {
    setInitialValue();
  }, 200);
  document.addEventListener('click', handleClickOutside);
  dropdowncontent.value.addEventListener('scroll', checkScroll);

  setTimeout(() => {
    checkScroll()
  }, 1000);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
  dropdowncontent.value.removeEventListener('scroll', checkScroll);
});

watch(() => props.modelValue, (newValue) => {
  handleModelChange(newValue)
});

</script>

<template>
  <div :id="id">
    <div class="w-100" :class="{
      'd-flex flex-column align-items-start flex-sm-row gapx-3 justify-content-between align-items-md-center': inlineLabel && !!label,
      'd-inline-block d-flex flex-column': !inlineLabel || !label,
      'fullWidth': fullWidth,
      'selected': selected,
      }">
      <label v-if="label" 
        class="form-label"
        :class="{'mb-2': !inlineLabel}">{{ label }}</label>

      <div 
        class="dropdown" 
        :class="{'disabled-select': props.disabled}" 
        @click="toggleDropdown" 
        ref="dropdown">

        <button 
          class="dropbtn position-relative" 
          :style="{ 'min-width': minWidth }"
          :class="{'isInvalid': error}" 
          @click.prevent><span class="text"><span class="text-front">{{ selected ?? placeholder }}</span></span></button>
    
        <div 
          class="dropdown__content" 
          :class="{
            'canScroll--up': canScrollUp, 
            'canScroll--down': canScrollDown, 
          }">
          <div class="dropdown__inner" ref="dropdowncontent" :class="{'isOpen': isOpen }">
            <a 
              href="#" 
              v-for="(option, optionIndex) in optionsArray" 
              :key="`${id}_option_${optionIndex}`" 
              @click.stop="selectOption(optionIndex, option)" v-html="option.label"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end optional-text p-1" v-if="optional">(optional)</div>
    <p v-if="error" class="error mt-2 text-danger text-start small" :class="{'text-sm-end':inlineLabel}">{{ error }}</p>
  </div>
</template>

<style lang="scss">
@import "@/sass/_vuestrap.scss";
.optional-text {
  font-size: 0.75rem;
  color: $gray-700;
}
.disabled-select {
  pointer-events: none;
  opacity: 0.5;
}

.dropdown {
  position: relative;
  display: inline-block;

  .dropbtn {
    background-color: #fff;
    color: $primary;
    padding: 7px 30px 7px 5px;
    border: 2px solid #444;
    border-radius: 6px;
    text-align: start;
    cursor: pointer;

    .text {
      padding: 2px 10px;
      display: inline-block;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 10px;
        width: calc(100% - 20px);
        height: 10px;
        background-color: transparent;
        z-index: 0;
      }
      .text-front {
        position: relative;
      }
    }

    .selected & {
      .text {
        &:before {
          background-color: #FFFF00;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 15px;
      background-image: url('@/assets/images/icons/selecticon2.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 100%;
      width: 9px;
    }
    &:hover, &:focus {
      // background-color: #EFEFEF;
      border-color: #999;
      .text {
        &:before {
          background-color: transparent;
        }
      }
    }

    &.isInvalid {
      border-color: $danger;
    }
  }

  &__content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 15px;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 15px;
      z-index: 1;

      transition: transform 0.2s ease-out;
    }

    &::before {
      top: 0px;
      background: linear-gradient(to bottom, rgba($primary, 0.2), rgba($primary, 0));
      transform: translateY(-120%);
    }

    &::after {
      bottom: 0px;
      background: linear-gradient(to top, rgba($primary, 0.2), rgba($primary, 0));
      transform: translateY(120%);
    }

    &.canScroll--up::before {
      transform: translateY(0);
    }

    &.canScroll--down::after {
      transform: translateY(0);
    }

    a {
      color: black;
      padding: 8px 10px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #FFFF00;
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 1;
    max-height: 0;
    overflow-y: auto;

    transition: max-height 0.2s ease-out;

    &.isOpen {
      max-height: 225px;
    }
  }

  .fullWidth & {
    width: 100%;
    .dropbtn {
      width: 100%;
    }
  }
}
</style>