<script setup lang="ts">
import { ref } from 'vue'

import TextInput from '@/components/form/TextInput.vue';
import Button from '@/components/form/Button.vue';

import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['closeModal'])

const linkSent = ref<boolean>(false)
const loading = ref<boolean>(false)
const error = ref<string>('')

const requestNewLink = async () => {
  loading.value = true;
  error.value = '';

  try {
    await useUserStore().requestVerifyEmailLink()

    loading.value = false;
    linkSent.value = true;

  } catch (requestError) {
    loading.value = false;
    error.value = requestError

  }
}

</script>

<template>
  <div>
    <div class="mb-3">
      <h4 class="h3">Verify Your Email Address</h4>
    </div>

    <div class="my-4">
      <p>To continue, please verify your email address. We have sent a verification link to your email.</p>
      
      <p class="mb-1"><b>Didn't receive the email?</b></p>
      <p>No worries! Just hit the button below, and we'll send you a new link.</p>
    </div>


    <p v-if="error" class="text-danger small">{{ error }}</p>

    <Button 
      class="btn-dark" 
      @click="requestNewLink()"
      :disabled="loading || linkSent" 
      :loading="loading">
    <span v-if="linkSent">Link Sent</span>
    <span v-else>Resend Verification Link</span>
    </Button>

  </div>

</template>
