import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useRouter } from 'vue-router'

import { LoginDetails } from '@/types/User.ts'
import { useWizardStore } from '@/stores/wizardStore';
import { useMessagesStore } from '@/stores/messengerStore.ts'
import { usePublicUserStore } from '@/stores/publicUserStore.ts'

import cookies from '@/helpers/cookies'
import { triggerEvent } from '@/helpers/general'
import axios from 'axios'

export const useUserStore = defineStore('user', () => {
  const router = useRouter()
  
  const authToken = ref(null)

  const user = ref({
    uuid: null,

    email: null,
    email_verified: false,
    mobile: null,

    first_name: null,
    last_name: null,
    about: null,
    
    avatar: null,
    user_type: null,
    field_details: null,

    primary_details: null,
    primary_rates: null,
    secondary_details: null,
    secondary_rates: null,
    other_details: null,

    work_details: null,
    availability: null,

    work_arrangement: null,
    location_details: null,

    sector_details: null,
    experience_details: null,
    qualifications_details: null,

    programming_details: null,
    framework_details: null,
    software_details: null,

    skills_details: null,

    experience_links_details: null,

    field: null,
  })

  const authSet = computed(() => !!authToken.value)
  const userType = computed(() => user.value.profile_type)
  const userComputed = computed(() => user.value)

  const getField = () => {
    switch (user.value.field) {
      case "design":
        return {
          name: 'design',
          noun: 'designer',
        }
        break;
      case "engineering":
        return {
          name: 'engineering',
          noun: 'developer',
        }
        break;
      case "advertising":
        return {
          name: 'advertising',
          noun: 'advertiser',
        }
        break;
      case "production":
        return {
          name: 'production',
          noun: 'producer',
        }
        break;
      case "people-managers":
        return {
          name: 'management',
          noun: 'manager',
        }
        break;
    }
  }

  const triggerLoginModal = () => {
    triggerEvent('openAuthModal', {
      modalToShow: 'login'
    })
  }

  const setToken = (token, setDefault) => {
    authToken.value = token

    if (setDefault) {
      cookies.setCookie(import.meta.env.VITE_COOKIE_NAME, token, 14)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }

  const logIn = (loginDetails: LoginDetails) => {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: '/v0/auth/login',
        data: loginDetails
      }).then(function (response) {
        handleLogin(response.data)

        resolve(true)
      }).catch(function (error) {
        reject(error.formatedMessage)
      });
    })
  }

  const logInWithToken = () => {
    return new Promise(function (resolve, reject) {
      console.log('router')
      const token = cookies.getCookie(import.meta.env.VITE_COOKIE_NAME)
      if (!token) {
        reject('No token found')
      }

      axios({
        method: 'get',
        url: '/v0/user',
        headers: {
          'Authorization': `Bearer ${ token }`
        }
      }).then(function (response) {
        setToken(token, true)

        handleLogin(response.data)
        resolve(true)
        
      }).catch(function (error) {
        reject(error.formatedMessage)
      });
    })
  }

  const logOut = () => {
    return new Promise(function (resolve) {
      authToken.value = null
      axios.defaults.headers.common['Authorization'] = null
      cookies.deleteCookie(import.meta.env.VITE_COOKIE_NAME)

      window.location.href = import.meta.env.VITE_UNAUTHENTICATED_URL
      resolve(true)
    })
  }

  const handleLogin = async (loginData: any) : void => {
    if (loginData.token) {
      setToken(loginData.token, true)
    }

    if (loginData.user) {
      user.value = loginData.user
      if(loginData.user.first_name == null) {
        let lsteps = localStorage.getItem(import.meta.env.VITE_COMPLETED_STEPS_NAME)
        if (lsteps != null) {
          localStorage.removeItem(import.meta.env.VITE_COMPLETED_STEPS_NAME)
          location.reload()
        }
      }

      if (!loginData.user.email_verified) {
        triggerEvent('openAuthModal', {
          modalToShow: 'verify-email'
        })
      }

      await router.isReady()
      const routerMeta = router.currentRoute.value.meta

      if (loginData.user.profile_type == 'talent') {
        if (!routerMeta?.hideWizard && loginData.user.about == null) {
          useWizardStore().setWizardSteps(loginData.user.field)
          useWizardStore().toggleWizard('setup')
  
        } else {
          localStorage.removeItem(import.meta.env.VITE_COMPLETED_STEPS_NAME)
          
          if (routerMeta.redirectToProfile) {
            router.push('/profile')
          }
        }
      } else if (loginData.user.profile_type == 'company') {
        useMessagesStore().fetchMessageHistory()
        usePublicUserStore().fetchviewedHistory()
      }
    }
  }

  const redirectToMarket = (bypassCheck: boolean = false) => {
    if ((bypassCheck || import.meta.env.VITE_UNAUTHENTICATED_REDIRECT == 'true') && !authSet.value) {
      window.location.href = import.meta.env.VITE_UNAUTHENTICATED_URL
    }
  }

  const updateUserCore = (data: any) => {
    return new Promise(function (resolve, reject) {
      const timeSubmitted = new Date().getTime()
      const requestDelay = 300

      axios({
        method: 'put',
        url: '/v0/user',
        data: data
      }).then(function (response) {
        let timeDelayRemaining = requestDelay - (new Date().getTime() - timeSubmitted)
        if (timeDelayRemaining < 0) {
          timeDelayRemaining = 10
        }

        user.value = response.data.user
        setTimeout(() => {
          resolve(true)
        }, timeDelayRemaining)

      }).catch(function (error) {
        reject(error.formatedMessage)
      });
    })
  }

  const updateUser = (data: any) => {
    return new Promise(function (resolve, reject) {
      const timeSubmitted = new Date().getTime()
      const requestDelay = 300

      axios({
        method: 'post',
        url: '/v0/user/user_details',
        data: data
      }).then(function (response) {
        let timeDelayRemaining = requestDelay - (new Date().getTime() - timeSubmitted)
        if (timeDelayRemaining < 0) {
          timeDelayRemaining = 10
        }

        user.value = response.data.user
        setTimeout(() => {
          resolve(true)
        }, timeDelayRemaining)

      }).catch(function (error) {
        reject(error.formatedMessage)
      });
    })
  }

  const requestVerifyEmailLink = () => {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: '/v0/auth/email/verification',
        data: {
          email: user.value.email
        }
      }).then(function (response) {
        resolve(true)
      }).catch(function (error) {
        reject(false)
      });
    })
  }

  const verifyEmail = (url: string) => {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: url
      }).then(function (response) {
        resolve(true)
      }).catch(function (error) {
        reject(false)
      });
    })
  }

  return { 
    authToken, 
    
    authSet,
    userType,
    userComputed, 
    getField,

    logIn,
    logInWithToken,
    logOut,
    triggerLoginModal,
    redirectToMarket,
    updateUserCore,
    updateUser,
    verifyEmail,
    requestVerifyEmailLink,
  }
})
