<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, nextTick, watch } from 'vue'
import { useRouter } from 'vue-router'

import ModalComponent from '@/components/ModalComponent.vue'
import VerifyEmail from './VerifyEmail.vue'
import LoginForm from './LoginForm.vue'
import ApplyForm from './ApplyForm.vue'
import GetStartedForm from './GetStartedForm.vue'

import { useUserStore } from '@/stores/userStore.ts'

const router = useRouter()

const modalToShow = ref<string>('')
const hideModal = ref<boolean>(true)

const closeModal = () => {
  if (modalToShow.value == 'login' && !useUserStore().authSet) {
    useUserStore().redirectToMarket()

  } else if (modalToShow.value == 'verify-email') {
    
  } else {

    modalToShow.value = ''
  }
}

const closeModalOnly = () => {
  modalToShow.value = ''
}

const openModal = async (triggerInfo) => {
  await router.isReady()
  
  if (triggerInfo?.detail?.modalToShow) {
    modalToShow.value = triggerInfo.detail.modalToShow
  }
}

onMounted(() => {
  addEventListener('openAuthModal', openModal)
})
onBeforeUnmount(() => {
  removeEventListener('openAuthModal', openModal)
})

watch(router.currentRoute, (newValue) => {
  hideModal.value = newValue?.meta?.hideModals == true
})
</script>



<template>
  <ModalComponent 
    @close="closeModal()" 
    @closeModal="closeModalOnly()"
    :showCloser="modalToShow != 'verify-email'"
    v-if="modalToShow && !hideModal">

    <ApplyForm 
      @closeModal="closeModal"
      v-if="modalToShow == 'apply'"/>

    <LoginForm 
      @closeModal="closeModal"
      v-if="modalToShow == 'login'"/>

    <VerifyEmail 
      @closeModal="closeModal"
      v-if="modalToShow == 'verify-email'"/>

    <GetStartedForm 
      @closeModal="closeModal"
      v-if="modalToShow == 'get-started'"/>

  </ModalComponent>
</template>