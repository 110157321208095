<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import BlockBtn from '@/components/wizard/Components/BlockBtn.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useUserStore } from '@/stores/userStore.ts'
import { useWizardStore } from '@/stores/wizardStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false)
const error = ref<string|null>(null)

const userType = ref<string|null>(null)
const showMoreInfo = ref<boolean>(false)

const userUserType = useUserStore().userComputed.user_type
if (userUserType) {
  userType.value = userUserType
}

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update what type of hooman you are'
  } else {
    return 'What kind of hooman are you?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const submitForm = async () => {
  if (!userType.value) {
    error.value = 'Please select an option above'
    return;
  }

  error.value = null

  loading.value = true;
  try {
    await useUserStore().updateUser({
      user_type: userType.value
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

watch(userType, (newVal) => {
  if (newVal) {
    error.value = null;
  }
})
</script>

<template>
  <StepLayout 
    maxWidth="1800px"
    :headline="headline">
    <template v-slot:steptip>
      <p class="text-center small text-muted pb-3">Not sure? <span class="small-link" @click="showMoreInfo = !showMoreInfo">Show <span v-if="!showMoreInfo">more</span><span v-else>less</span> about the three types.</span> 🤓</p>
    </template>

    <form @submit.prevent="submitForm">
      
      <div class="d-flex flex-wrap gap-3 justify-content-center">
        <div class="helper-text-parent">
          <BlockBtn 
            :isChecked="userType == 'Leading the team'"
            @btnClicked="userType = 'Leading the team'"
            materialIcon="supervisor_account"
            label="Leading the&nbsp;team" 
            :error="!!error"/>
            <p v-if="showMoreInfo" class="helper-text p-3 mb-2">
              <b>You're a leader</b>, the one who takes charge and makes sure the team is on track to success.
            </p>
        </div>
        <div class="helper-text-parent">
          <BlockBtn 
            :isChecked="userType == 'Part of the team'"
            @btnClicked="userType = 'Part of the team'"
            materialIcon="groups_2"
            label="Part of the&nbsp;team" 
            :error="!!error"/>
            <p v-if="showMoreInfo" class="helper-text p-3 mb-2">
                <b>You're a team player</b>, you work well with others and you're a key part of the team's success.
            </p>
        </div>
        <div class="helper-text-parent">
          <BlockBtn 
            :isChecked="userType == 'Lone wolf'"
            @btnClicked="userType = 'Lone wolf'"
            materialIcon="nature_people"
            label="Lone wolf" 
            :error="!!error"/>
            <p v-if="showMoreInfo" class="helper-text p-3 mb-2">
                <b>You work best on your own</b>, you're self-sufficient and get the job done without needing a team.
            </p>
        </div>
      </div>
    
      <StepFooter 
      :error="error" 
      :loading="loading"
      :label="buttonLabel"
      />
    </form>
  </StepLayout>
</template>

<style lang="scss" scoped>
</style>
