<script setup lang="ts">
import { computed, ref } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TextInput from '@/components/form/TextInput.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useVuelidate } from '@vuelidate/core'
import { minLength, maxLength, required, helpers } from '@vuelidate/validators'

import { useUserStore } from '@/stores/userStore.ts'
import { useWizardStore } from '@/stores/wizardStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);
const error = ref<string|null>(null);

const placeholders = [
  ["Captain", "Obvious"],
  ["Sherlock", "Holmes"],
  ["Princess", "Consuela Banana-Hammock"],
  ["Count", "Dracula"],
  ["Kitty", "Cat"],
  ["Sir", "Mix-a-Lot"],
  ["Jedi", "Master"],
  ["James T.", "Kirk"],
]
const placeholderIndex = Math.floor(Math.random() * placeholders.length)

const firstName = ref<string>('');
const lastName = ref<string>('');

const userStore = useUserStore().userComputed

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Make changes to your name'
  } else {
    return 'Hi 👋 Let\'s create your profile.'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

if (userStore) {
  firstName.value = userStore.first_name ?? ''
  lastName.value = userStore.last_name ?? ''
}

const v$ = useVuelidate({
  firstName: { 
    required: helpers.withMessage('First name is required.', required),
    minLength: helpers.withMessage('First name should be atleast 2 characters.', minLength(2)),
    maxLength: helpers.withMessage('First name should cant be more than 100 characters.', maxLength(100)),
  },
  lastName: { 
    required: helpers.withMessage('Last name is required.', required),
    minLength: helpers.withMessage('Last name should be atleast 2 characters.', minLength(2)),
    maxLength: helpers.withMessage('Last name should cant be more than 100 characters.', maxLength(100)),
  },
}, { firstName, lastName }, { $lazy: true });

const submitForm = async () => {
  v$.value.$touch();
  if (v$.value.$invalid) return;

  error.value = null
  loading.value = true;
  try {
    await useUserStore().updateUserCore({
      first_name: firstName.value,
      last_name: lastName.value,
    })

    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}
</script>

<template>
  <StepLayout 
    maxWidth="640px"
    :headline="headline">
    <form @submit.prevent="submitForm">
      <div class="row justify-content-center">
        <div class="col-12 col-md-6 mb-3 mb-md-0">
          <TextInput
            id="firstName"
            label="First Name"
            :placeholder="placeholders[placeholderIndex][0]"
            v-model="v$.firstName.$model"
            :error="(v$.firstName.$dirty && v$.firstName.$invalid) ? v$.firstName.$errors[0].$message : ''" />
        </div>
        <div class="col-12 col-md-6 mb-3 mb-md-0">
          <TextInput
            id="firstName"
            label="Last Name"
            :placeholder="placeholders[placeholderIndex][1]"
            v-model="v$.lastName.$model"
            :error="(v$.lastName.$dirty && v$.lastName.$invalid) ? v$.lastName.$errors[0].$message : ''" />
        </div>
      </div>
    
      <StepFooter 
      :error="error" 
      :loading="loading"
      :label="buttonLabel"
       />
    </form>
  </StepLayout>
</template>
