<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TagQualification from '@/components/wizard/Components/TagQualification.vue'
import TextInput from '@/components/form/TextInput.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const linkedin = ref<string>('')
const portfolio = ref<string>('')
const other = ref<string>('')

const userLinks = useUserStore().userComputed.experience_links_details

const loading = ref<boolean>(false);

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update the links to you online presence'
  } else {
    return 'Add links to your online presence'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Almost there!'
  }
})

const showMoreInfo = ref<boolean>(false)

if (userLinks) {
  linkedin.value = userLinks.linkedin
  portfolio.value = userLinks.portfolio
  other.value = userLinks.other
}

const FormError = ref<string|null>(null);
const updateLinkedIn = (value: string) => {
  linkedin.value = value
}

const submitForm = async () => {

  if (linkedin.value === '') {
    FormError.value = 'Please add your linkedIn profile link'
    return
  }

  FormError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      experience_links_details: JSON.stringify({
        linkedin: linkedin.value,
        portfolio: portfolio.value,
        other: other.value
      })
    })
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    FormError.value = e
  }
}

</script>

<template>
  <StepLayout maxWidth="950px" :headline="headline">

    <form @submit.prevent="submitForm">

      <div class="mx-auto clientForm" style="max-width:350px">
        <div class="d-flex w-100 flex-column gap-3">
          <TextInput
            id="LinkedIn"
            label="LinkedIn"
            placeholder="linkedin.com/myhandle"
            @input="updateLinkedIn($event.target.value)"
            @paste="updateLinkedIn($event.target.value)"
            v-model="linkedin"
            tip='Tip: Copy and paste from <a href="https://linkedin.com/" target="_blank">LinkedIn</a>'
            class="w-100"
            />

          <TextInput
            id="newClientWebsite"
            label="Portfolio"
            placeholder="mysite.com"
            v-model="portfolio"
            class="w-100"
            optional="true"
            />

          <TextInput
            id="newClientWebsite"
            label="Other"
            placeholder="linktree.com/myhandle"
            v-model="other"
            class="w-100"
            optional="true"
            />
        </div>
      </div>

      <StepFooter :loading="loading" :label="buttonLabel" :disabled="linkedin == ''" :error="FormError" />
    </form>
  </StepLayout>
</template>

<style>
.link-list {
  padding: 0.3rem 1rem;
  border-radius: 6px;
  border: 2px solid #000;
}
.tag-av span {
  font-size: 2em!important;
  opacity: 0.8;
}
.tag-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>