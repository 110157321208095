import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import axios from 'axios'

export const usePublicUserStore = defineStore('publicUser', () => {
  const viewed = ref([])
  const publicUser = ref({})
  
  const viewedHistory  = computed(() => viewed.value)
  const publicUserComputed = computed(() => publicUser.value)
  
  const fetchviewedHistory = () => {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: '/v0/viewed_history'
      }).then(function (response) {
        viewed.value = response.data.history
        resolve(true)
      }).catch(function (error) {
        reject(error.formatedMessage)

      });
    })
  }

  const removeView = (index : number) => {
    let viewedId = viewed.value[index].id
    axios({
      method: 'post',
      url: `/v0/viewed_history/hide/${ viewedId }`
    })
    viewed.value.splice(index, 1)
  }
  
  const fetchUserProfile = (userId: string) => {
    publicUser.value = {}
    return new Promise(function (resolve, reject) {
      
      let url = `/v0/user/${ userId }`
      if (axios.defaults.headers.common['Authorization']) {
        url = `/v0/u/${ userId }`
      }

      axios({
        method: 'get',
        url: url
      }).then(function (response) {
        publicUser.value = response.data.user
        
        if (response.data.new_history) {
          viewed.value = viewed.value.filter((user) => user.user_uuid !== response.data.new_history.user_uuid)
          viewed.value.unshift(response.data.new_history)
        }

        resolve(true)
      }).catch(function (error) {
        reject(error.formatedMessage)

      });
    })
  }

  return { 
    publicUserComputed,
    viewedHistory,
    removeView,
    fetchviewedHistory,
    fetchUserProfile,
  }
})
