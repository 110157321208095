<script setup lang="ts">
import { ref, watch } from 'vue'

import ModalComponent from '@/components/ModalComponent.vue'
import Button from '@/components/form/Button.vue'
import TextInput from '@/components/form/TextInput.vue'


const props = defineProps({
  modalData: {
    type: Object as () => object|null,
    required: true,
  }
});

// page variables
const emit = defineEmits(['closeModal'])
const error = ref<string|null>(null);

const aiData = ref<object>({
  passions: '',
  afterWork: '',
});

const passionsError = ref<string|null>(null)
const afterWorkError = ref<string|null>(null)

if (props.modalData) {
  aiData.value = props.modalData
}


const closeModal = (submit: boolean = false) => {
  let submitData = false
  error.value = null

  if (submit) {
    if (!aiData.value.passions) {
      passionsError.value = 'Please enter a response'
    }
    if (!aiData.value.afterWork) {
      afterWorkError.value = 'Please enter a response'
    }
    if (passionsError.value || afterWorkError.value) return;

    submitData = aiData.value
  }

  emit('closeModal', submitData)
}

watch(() => aiData.value.passions, (newValue) => {
  if (newValue.length >= 1) {
    passionsError.value = null
  } else {
    passionsError.value = 'Please enter a response'
  }
})

watch(() => aiData.value.afterWork, (newValue) => {
  if (newValue.length >= 1) {
    afterWorkError.value = null
  } else {
    afterWorkError.value = 'Please enter a response'
  }
})
</script>

<template>
  <ModalComponent 
    showCloser 
    maxWidth="730px"
    @close="closeModal()">
  
    <div class="mb-4">
      <h3 class="mb-4">Let's create your intro</h3>
      <p class="m-0 me-md-3">Use AI to create a few options for you to use as a starting point.</p>
    </div>
  
    <form @submit.prevent="closeModal(true)">
  
      <div class="inputGroup">
        <p class="fw-bold mb-3">Two quick questions:</p>
        
        <div class="d-flex gap-3 mb-3">
          <TextInput
            id="loveMyJob"
            label="What's your favorite part of what you do?"
            placeholder="Eg. Meeting new people and solving problems"
            class="w-100"
            v-model="aiData.passions"
            :error="passionsError" />
        </div>

        <div class="d-flex gap-3 mb-3">
          <TextInput
            id="downTime"
            label="What do you like to do in your free time?"
            placeholder="Eg. Hiking, reading, or cooking"
            class="w-100"
            v-model="aiData.afterWork"
            :error="afterWorkError" />
        </div>
        
      </div>
  
      <div class="text-center mt-4 mb-n4">
        <p class="text-danger" v-if="error">{{ error }}</p>
        <Button type="submit" class="btn-dark px-5">Generate</Button>
      </div>      
    </form>
  
  </ModalComponent>
</template>