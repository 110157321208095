<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import ModalComponent from '@/components/ModalComponent.vue'
import Button from '@/components/form/Button.vue'
import SelectButtons from '@/components/form/SelectButtons.vue'
import LocationInput from '@/components/form/LocationInput.vue'

const props = defineProps({
  modalData: {
    type: Object as () => object|null,
    required: true,
  }
});

// page variables
const emit = defineEmits(['closeModal'])
const error = ref<string|null>(null)
const locationValue = ref<object|null>(null)
const selectedLocation = ref<string|null>(null)
const otherLocation = ref<object>({
  city: '',
  lat: null,
  lng: null,
})

if (props.modalData) {
  locationValue.value = props.modalData
  if (props.modalData.other) {
    selectedLocation.value = 'Other'
    otherLocation.value = locationValue.value
  } else {
    selectedLocation.value = props.modalData.city
  }
}

const locationOptions = ['Cape Town', 'Johannesburg', 'Durban', 'Other']

const locationsArray = [
  { "city": "Cape Town", "lat": -33.922087, "lng": 18.4231418 },
  { "city": "Johannesburg", "lat": -26.205647, "lng": 28.0337185 },
  { "city": "Durban", "lat": -29.85868039999999, "lng": 31.0218404 },
]

const fillLocation = () => {
  let locationMatch = locationsArray.find((location) => location.city == selectedLocation.value)
  if (locationMatch) {
    locationValue.value = locationMatch
    locationValue.value.other = false
  }
}

if (props.modalData) {
  locationValue.value = props.modalData
}

const closeModal = (submit: boolean = false) => {
  let submitData = {
    locationValue: locationValue.value,
  }
  error.value = null

  if (submit) {
    if (!locationValue.value || !locationValue.value.city) {
      error.value = 'Please select a location above'
    }
    if (!otherLocation.value && selectedLocation.value == 'Other') {
      error.value = 'Please find your city in the search box above'
    }
    if (error.value ) return;
  }

  emit('closeModal', submitData)
}

watch(selectedLocation, (newVal) => {
  if (newVal) {
    error.value = null;
  }
  if (newVal == 'Other') {
    locationValue.value = otherLocation.value
    locationValue.value.other = true
  } else {
    fillLocation()
  }
})
</script>

<template>
  <ModalComponent 
    showCloser 
    maxWidth="730px"
    @close="closeModal()">
  
    <div class="mb-4">
      <h3 class="mb-4">Where are you based?</h3>
      <p class="m-0">Select your location from the options below.</p>
    </div>
  
    <form @submit.prevent="closeModal(true)">
  
  <div class="inputGroup py-4 mx-n4 mx-sm-0">
    <p class="mb-4">Select your city:</p>
      <SelectButtons 
        id="currentLevel"
        :optionsArray="locationOptions"
        v-model="selectedLocation"
        class="max2-up mb-2" />
  </div>
  
  <div class="inputGroup p-4 mx-n4 mx-sm-0" v-if="selectedLocation == 'Other'">
    <div class="d-flex flex-column flex-md-row gap-2 align-items-start align-items-md-center justify-content-between">
      <LocationInput 
        id="locationInput"
        v-model="otherLocation"
        label="Find your city:"
        fieldLayout="inline" 
        placeholder="Type your city here"
        class="w-100 mb-0"
        :class="{ 'is-error': error, 'is-filled': otherLocation}" />
    </div>
  </div>
      
      <div class="text-center mt-4 mb-n4">
        <p class="text-danger" v-if="error">{{ error }}</p>
        <Button type="submit" class="btn-dark px-6">Continue</Button>
      </div>      
    </form>
  
  </ModalComponent>
</template>

<style scoped>
.max-50 {
  max-width: 350px;
}
.max2-up {
  max-width: 410px;
  margin: 0 auto;
}
.datepicker {
  padding: 9px 15px; 
  position: relative;
  border: 2px solid #666;
  &:hover {
    border-color: #999;
  }
  &.is-filled {
    &:after {
      content: '';
      position: absolute;
      right: 50px;
      left: 10px;
      bottom: 9px;
      height: 9px;
      background-color: #FFFF00;
      mix-blend-mode: multiply;
    }
    &:hover {
      &:after {
        content: none;
      }
    }
  }
}
</style>