<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import TagSelector from '@/components/wizard/Components/TagSelector.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'

import { useWizardStore } from '@/stores/wizardStore.ts'
import { useUserStore } from '@/stores/userStore.ts'
import { useRoleStore } from '@/stores/roleStore'

const emit = defineEmits(['removeComponent', 'addComponent'])

const loading = ref<boolean>(false);


const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update the list of software you use'
  } else {
    return 'What software do you use?'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const selectedSoftware = ref<Array<object>>([]);
const userSoftware = useUserStore().userComputed.software_details

if (userSoftware) {
  selectedSoftware.value = userSoftware
}

const SoftwareError = ref<string|null>(null);
const Software = ref([
  { value: '', label: '' },
])
const dataSoft = useRoleStore().getDesignSoftware

if (dataSoft) {
  Software.value = dataSoft
}

const mergedSoftware = computed(() => {
  //combine the two arrays and remove duplicates
  let combined = [...Software.value, ...selectedSoftware.value].filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.value === item.value
    ))
  )
  return combined.sort((a, b) => a.label.localeCompare(b.label))
})

const submitForm = async () => {
  if (selectedSoftware.value.length === 0) {
    SoftwareError.value = 'Please select at least one package above'
    return
  }

  SoftwareError.value = null
  loading.value = true;

  try {
    await useUserStore().updateUser({
      software_details: JSON.stringify(selectedSoftware.value)
    })
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    SoftwareError.value = e
  }
}

const toggleClientLanguage = (selectedSoft: object) => {
  let selected = selectedSoftware.value.findIndex((selectedRoles) => selectedRoles.value == selectedSoft.value) > -1
  if (selected) {
    removeLanguage(selectedSoft)
  } else {
    selectedSoftware.value.push(selectedSoft)
  }
}

const removeLanguage = (language: object) => {
  selectedSoftware.value = selectedSoftware.value.filter((selected) => {
    return selected.value !== language.value
  })
}

watch(selectedSoftware, (newVal) => {
  if (newVal) {
    SoftwareError.value = null
  }
})
</script>

<template>
<StepLayout 
  maxWidth="850px"
  :headline="headline">
  <template v-slot:steptip>
    <p class="text-center small text-muted pb-3">Select from the list below or filter and add your own.</p>
  </template>

  <form @submit.prevent="submitForm">
    <TagSelector
      setMax="20"
      :optionsArray="mergedSoftware"
      :selectedArray="selectedSoftware"
      :error="SoftwareError"
      @update:modelValue="toggleClientLanguage($event)"
    />

    <StepFooter :error="SoftwareError" :loading="loading" :label="buttonLabel" />
  </form>
</StepLayout>
</template>