<script setup lang="ts">
import { ref } from 'vue'

import TextInput from '@/components/form/TextInput.vue';
import PasswordInput from '@/components/form/PasswordInput.vue'
import Button from '@/components/form/Button.vue';

import { useVuelidate } from '@vuelidate/core'
import { url, email, required, helpers } from '@vuelidate/validators'

import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['closeModal'])

const loading = ref<boolean>(false)
const error = ref<string|null>(null)

const emailAddress = ref<string>('');
const linkedInUrl = ref<string>('');
const portfolioUrl = ref<string>('');

const v$ = useVuelidate({
  emailAddress: {
    required: helpers.withMessage('Email is required', required),
    email: helpers.withMessage('Valid email address required.', email)
  },
  linkedInUrl: { 
    validUrl: helpers.withMessage('Valid url required.', url),
    linkedInUrl: helpers.withMessage('Valid linkedin url required.', function(value: string) : boolean {
      return value.startsWith('https://linkedin.com/')
    }),
  },
  portfolioUrl: { 
    validUrl: helpers.withMessage('Valid url required.', url),
  },
}, { emailAddress, linkedInUrl, portfolioUrl }, { $lazy: true });

const login = async () => {
  v$.value.$touch();
  if (v$.value.$invalid) return;

  // loading.value = true;
  // try {
  //   await useUserStore().logIn({
  //       email: emailAddress.value,
  //       password: password.value,
  //       remember: true
  //     })

  //   loading.value = false;
  //   emit('closeModal')

  // } catch (requestError) {
  //   loading.value = false;
  //   error.value = requestError

  // }
}

</script>

<template>
  <div>
    <div>
      <h4 class="h3">Apply</h4>
      <p>Join a vibrant community that celebrates innovation and collaboration. Apply now and redefine the future of creativity with us!</p>
    </div>

    <form @submit.prevent="login" class="d-flex flex-column gap-3">
      <TextInput
        id="email"
        label="Email Address"
        placeholder="you@cooldomain.com"
        v-model="v$.emailAddress.$model"
        :error="(v$.emailAddress.$dirty && v$.emailAddress.$invalid) ? v$.emailAddress.$errors[0].$message : ''" />

      <TextInput
        id="linkedInUrl"
        label="LinkedIn Profile"
        placeholder="https://linkedin.com/you"
        v-model="v$.linkedInUrl.$model"
        :error="(v$.linkedInUrl.$dirty && v$.linkedInUrl.$invalid) ? v$.linkedInUrl.$errors[0].$message : ''" />

      <TextInput
        id="portfolioUrl"
        label="Portfolio Link"
        placeholder="https://linkedin.com/you"
        v-model="v$.portfolioUrl.$model"
        :error="(v$.portfolioUrl.$dirty && v$.portfolioUrl.$invalid) ? v$.portfolioUrl.$errors[0].$message : ''" />
      
      <div class="text-end">
        <Button 
          class="btn-dark" 
          :disabled="loading" 
          :loading="loading">Submit</Button>
      </div>
    </form>

  </div>

</template>
